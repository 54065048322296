import { loginAction } from '../actionType'
import { connectWalletAction } from '../actionType'
import config from '../../config/config'
import axios from 'axios'

export const adminLoginInitiate = () => ({
  type: loginAction.ADMIN_LOGIN_INITIATE,
})

export const adminLoginSuccess = (data) => ({
  type: loginAction.ADMIN_LOGIN_SUCCESS,
  payload: data,
})

export const adminLoginFailure = (data) => ({
  type: loginAction.ADMIN_LOGIN_FAILURE,
})

export function adminLogin(payload) {
  return (dispatch) => {
    dispatch(adminLoginInitiate())
    return new Promise((resolve, reject) =>
      axios
        .post(`${config.Url}adminLogin`, payload)
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(adminLoginSuccess(data))
          } else {
            dispatch(adminLoginFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(adminLoginFailure(err))
          reject(err)
        }),
    )
  }
}

export const adminConnectWalletInitiate = () => ({
  type: connectWalletAction.ADMIN_CONNECTWALLET_INITIATE,
})

export const adminConnectWalletSuccess = (data) => ({
  type: connectWalletAction.ADMIN_CONNECTWALLET_SUCCESS,
  payload: data,
})

export const adminConnectWalletFailure = (data) => ({
  type: connectWalletAction.ADMIN_CONNECTWALLET_FAILURE,
})

export function adminConnectWallet(payload) {
  return (dispatch) => {
    dispatch(adminConnectWalletInitiate())
    return new Promise((resolve, reject) =>
      axios
        .post(`${config.Url}connectAdminWallet`, payload)
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(adminConnectWalletSuccess(data))
          } else {
            dispatch(adminConnectWalletFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(adminConnectWalletFailure(err))
          reject(err)
        }),
    )
  }
}


export const adminForgetInitiate = () => ({
  type: loginAction.ADMIN_FORGET_PASSWORD_INITIATE,
})

export const adminForgetSuccess = (data) => ({
  type: loginAction.ADMIN_FORGET_PASSWORD_SUCCESS,
  payload: data,
})

export const adminForgetFailure = (data) => ({
  type: loginAction.ADMIN_FORGET_PASSWORD_FAILURE,
})

export function adminForgetPassword(payload) {
  return (dispatch) => {
    dispatch(adminForgetInitiate())
    return new Promise((resolve, reject) =>
      axios
        .post(`${config.Url}forgetPassword`, payload)
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(adminForgetSuccess(data))
          } else {
            dispatch(adminForgetFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(adminForgetFailure(err))
          reject(err)
        }),
    )
  }
}


export const verifyOtpInitiate = () => ({
  type: loginAction.ADMIN_VERIFYOTP_INITIATE,
})

export const verifyOtpSuccess = (data) => ({
  type: loginAction.ADMIN_VERIFYOTP_SUCCESS,
  payload: data,
})

export const verifyOtpFailure = (data) => ({
  type: loginAction.ADMIN_VERIFYOTP_FAILURE,
})

export function verifyOtp(payload) {
  return (dispatch) => {
    dispatch(verifyOtpInitiate())
    return new Promise((resolve, reject) =>
      axios
        .post(`${config.Url}verifyOtp`, payload)
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(verifyOtpSuccess(data))
          } else {
            dispatch(verifyOtpFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(verifyOtpFailure(err))
          reject(err)
        }),
    )
  }
}

export const resendOtpInitiate = () => ({
  type: loginAction.ADMIN_RESENDOTP_INITIATE,
})

export const resendOtpSuccess = (data) => ({
  type: loginAction.ADMIN_RESENDOTP_SUCCESS,
  payload: data,
})

export const resendOtpFailure = (data) => ({
  type: loginAction.ADMIN_RESENDOTP_FAILURE,
})

export function resendOtp(payload) {
  return (dispatch) => {
    dispatch(resendOtpInitiate())
    return new Promise((resolve, reject) =>
      axios
        .post(`${config.Url}resendOtp`, payload)
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(resendOtpSuccess(data))
          } else {
            dispatch(resendOtpFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(resendOtpFailure(err))
          reject(err)
        }),
    )
  }
}

export const resetPasswordInitiate = () => ({
  type: loginAction.ADMIN_RESET_PASSWORD_INITIATE,
})

export const resetPasswordSuccess = (data) => ({
  type: loginAction.ADMIN_RESET_PASSWORD_SUCCESS,
  payload: data,
})

export const resetPasswordFailure = (data) => ({
  type: loginAction.ADMIN_RESET_PASSWORD_FAILURE,
})

export function resetPassword(payload) {
  return (dispatch) => {
    dispatch(resetPasswordInitiate())
    return new Promise((resolve, reject) =>
      axios
        .post(`${config.Url}resetPassword`, payload)
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(resetPasswordSuccess(data))
          } else {
            dispatch(resetPasswordFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(resetPasswordFailure(err))
          reject(err)
        }),
    )
  }
}
