import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { Button, FormGroup, Input } from 'reactstrap';
import { transactionList } from "../../../redux/action/transactionAction";
import { FiDownload } from "react-icons/fi"
import { useProvider } from "wagmi";
import { FaCalendarAlt } from "react-icons/fa"
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { TbRefresh } from "react-icons/tb"
import { CSVLink } from "react-csv";

const initialState = {
    fromDate: "",
    toDate: "",
};

const Transactionsidebar = () => {
    const dispatch = useDispatch();
    const [perPage, SetcurrentPage] = useState(10)
    const [iState, updateState] = useState(initialState);
    const [search, setSearch] = useState('')
    const listData = useSelector((state) => state.transaction);
    const { transactionListData } = listData;
    const [chain, setchain] = useState('');
    const filename = 'transaction.csv';
    const provider = useProvider();
    let countTrnData = transactionListData && (transactionListData.data && transactionListData.data[0].total.length > 0) ? transactionListData.data[0].total[0].count / 10 : []
    let data2 = transactionListData.data && transactionListData.data[0].data.length > 0 ? transactionListData.data && transactionListData.data[0].data : []


    const getChain = async () => {
        let aw = await provider.getNetwork()
        setchain(aw)
    }
    useEffect(() => {
        getChain()
    })
    useEffect(() => {
        let chainId = chain.chainId === 80001 ? 80001 : 97
        let obj = { searchVal: '', pageNumber: 1, chainId: chainId }
        dispatch(transactionList(obj));
    }, [chain]);


    const transactionIdChange = (trId) => {
        if (trId) {
            let ar = trId.split('')
            ar.splice(4, 50, ".....")
            return ar.join('')
        }

    }

    const fromAddressChange = (fromAddress) => {
        if (fromAddress) {
            let ar = fromAddress.split('')
            ar.splice(4, 34, ".....")
            return ar.join('')
        }

    }
    const handlePageChange = (e) => {
        const selectedPage = e.selected + 1
        SetcurrentPage(10 * selectedPage)
        let chainId = chain.chainId === 80001 ? 80001 : 97
        let obj = { searchVal: '', pageNumber: selectedPage, chainId }
        dispatch(transactionList(obj))
    }

    const handleSearch = (e) => {
        const { name, value } = e.target;
        setSearch(value)
        let chainId = chain.chainId === 80001 ? 80001 : 97
        let obj = { searchVal: value, pageNumber: 1, chainId }
        dispatch(transactionList(obj));
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        updateState({
            ...iState,
            [name]: value
        });
    }

    const handleEvent = (event, picker) => {
        console.log(picker.startDate);
    }
    const handleCallback = (start, end) => {
        let fromDate = moment(start._d).format("YYYY-MM-DD")
        let toDate = moment(end._d).format("YYYY-MM-DD")
        let chainId = chain.chainId === 80001 ? 80001 : 97
        let obj = { searchVal: '', pageNumber: 1, fromDate, toDate, chainId }
        dispatch(transactionList(obj));
    }
    const refreshPage = () => {
        let chainId = chain.chainId === 80001 ? 80001 : 97
        let obj = { searchVal: '', pageNumber: 1, chainId }
        dispatch(transactionList(obj));
        setSearch("")
    }
    return (
        <>
            <div className="dashRightside">
                <div className="transBg">
                    <div className="transUserOuter">
                        <div className="transHd">Gamers Transactions</div>
                        <div className="transFltr">
                            <div className="downBtn">

                                <CSVLink data={data2} filename={filename}>
                                    <Button><FiDownload /> </Button>
                                </CSVLink>

                            </div>
                            <div className="dateInpBx">
                                <DateRangePicker
                                    onEvent={handleEvent} onCallback={handleCallback}
                                >
                                    <input type="text" className="dateInp" />
                                </DateRangePicker>
                                <FaCalendarAlt />
                            </div>

                            <div className="srchBx">
                                <FormGroup>
                                    <Input type="text" name="search" id="exampleEmail" placeholder="Search..." value={search} onChange={handleSearch} />
                                </FormGroup>
                            </div>
                            <div className="refreshBtn">
                                <Button onClick={refreshPage}><TbRefresh /></Button>
                            </div>
                        </div>
                    </div>

                    <div className="tableBx">
                        <table cellPadding="0" cellSpacing="0" border="0">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Tx. ID</th>
                                    <th>Date</th>
                                    <th>Value</th>
                                    <th>Value</th>
                                    <th>From</th>
                                    <th>To</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>

                                {transactionListData.data &&
                                    transactionListData.data[0].data.length > 0 ? (
                                    transactionListData.data[0].data.map((data, i) => (
                                        <tr key={i}>
                                            <td>{i + 1 + (perPage - 10)}</td>
                                            <td>

                                                <a href={`${data.chainId === 80001
                                                    ? `https://mumbai.polygonscan.com/tx/${data.hash}`
                                                    : `https://testnet.bscscan.com/tx/${data.hash}`
                                                    }`}
                                                    target="_blank"
                                                >{transactionIdChange(data.hash)}
                                                </a>
                                            </td>
                                            <td>{moment(data.createdAt).format("DD/MM/YYYY")}</td>
                                            <td>{data.bnbValue && data.bnbValue.toFixed(4)} {data.chainId === 80001 ? "Matic" : "BNB"} </td>
                                            <td>{data.gspValue} GSP</td>
                                            <td>{fromAddressChange(data.from)}</td>
                                            <td>{fromAddressChange(data.to)}</td>
                                            <td>
                                                <span className="approved">{data.status}</span>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr><td>Data not found</td></tr>
                                )
                                }

                            </tbody>
                        </table>
                    </div>
                    <div className="paginationBx">
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={Math.ceil(countTrnData)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChange}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Transactionsidebar;
