import React from "react";
import { Button } from "reactstrap";
import Logo from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";
import "./header.scss";

const Header = () => {
  const logOut = () => {
    localStorage.removeItem('userData');
    window.location.assign("./");
  }
  return (
    <>
      <header>
        <div className="headLeft">
          <Link to="/dashboard">
            <img src={Logo} alt="" />
          </Link>
        </div>
        {/* <div className="headRight">
            <Button>Connect Wallet</Button>
        </div> */}
      </header>
    </>
  );
};

export default Header;
