import React, { useState, useEffect } from "react";
import HeaderAfterLogin from "../../common-components/header-afterlogin/header-afterlogin";
 import "./user.scss";
 import SideBar from '../../common-components/sidebar/sidebar';
 import Usersidebar from "./user-sidebar";
const User = () => {

  return (
    <>
      <div className="mainBg">
        <div className="dash_container">
          <HeaderAfterLogin />

          <div className="DashMidBx">
            <SideBar />
            <Usersidebar />
          </div>
        </div>
      </div>
    </>
  );
};

export default User;
