import React, { useState, useEffect } from "react";
import HeaderAfterLogin from "../../common-components/header-afterlogin/header-afterlogin";
import SideBar from '../../common-components/sidebar/sidebar';
import DetailsRightsidebar from './nfts-details-rightsidebar';
import "./nfts-details.scss";


const Nfts = () => {

  return (
    <>
      <div className="mainBg">
        <div className="dash_container">
          <HeaderAfterLogin />

          <div className="DashMidBx">
            <SideBar />
            <DetailsRightsidebar />
          </div>
        </div>
      </div>
    </>
  );
};

export default Nfts;
