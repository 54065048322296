import { transactionAction } from '../actionType'
import config from '../../config/config'
import axios from 'axios'
//----------------------------------- TRANSACTION LIST ---------------------------------------//

export const transactionListInitiate = () => ({
  type: transactionAction.TRANSACTION_LIST_INITIATE,
})

export const transactionListSuccess = (data) => ({
  type: transactionAction.TRANSACTION_LIST_SUCCESS,
  payload: data,
})

export const transactionListFailure = () => ({
  type: transactionAction.TRANSACTION_LIST_FAILURE,
})

export function transactionList(payload) {
  return (dispatch) => {
    let Fdate = payload.fromDate ? payload.fromDate : ''
    let Tdate = payload.toDate ? payload.toDate : ''
    dispatch(transactionListInitiate())
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}transactionList?pageNumber=${payload.pageNumber}&search=${payload.searchVal}&fromDate=${Fdate}&toDate=${Tdate}&chainId=${payload.chainId}`, {
          headers: {}
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(transactionListSuccess(data))
          } else {
            dispatch(transactionListFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(transactionListFailure(err))
          reject(err)
        }),
    )
  }
}