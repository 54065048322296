import { nftAction } from '../actionType'
import config from '../../config/config'
import { multiPartData } from '../../utils'
import axios from 'axios'

export const createNftInitiate = () => ({
  type: nftAction.CREATE_NFT_INITIATE,
})

export const createNftSuccess = (data) => ({
  type: nftAction.CREATE_NFT_SUCCESS,
  payload: data,
})

export const createNftFailure = (data) => ({
  type: nftAction.CREATE_NFT_FAILURE,
})

export function createNft(payload) {
  return (dispatch) => {
    const data = multiPartData(payload)
    dispatch(createNftInitiate())
    return new Promise((resolve, reject) =>
      axios
        .post(`${config.Url}createNft`, data, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(createNftSuccess(data))
          } else {
            dispatch(createNftFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(createNftFailure(err))
          reject(err)
        }),
    )
  }
}


//----------------------------------- Nft LIST ---------------------------------------//

export const NftListInitiate = () => ({
  type: nftAction.NFT_LIST_INITIATE,
})

export const NftListSuccess = (data) => ({
  type: nftAction.NFT_LIST_SUCCESS,
  payload: data,
})

export const NftListFailure = () => ({
  type: nftAction.NFT_LIST_FAILURE,
})

export function NftList(payload) {
  return (dispatch) => {
    dispatch(NftListInitiate())
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}NftList?pageNumber=${payload}`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(NftListSuccess(data))
          } else {
            dispatch(NftListFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(NftListFailure(err))
          reject(err)
        }),
    )
  }
}

//----------------------------------- Nft DETAIL ---------------------------------------//

export const NftDetailInitiate = () => ({
  type: nftAction.NFT_DETAIL_INITIATE,
})

export const NftDetailSuccess = (data) => ({
  type: nftAction.NFT_DETAIL_SUCCESS,
  payload: data,
})

export const NftDetailFailure = () => ({
  type: nftAction.NFT_DETAIL_FAILURE,
})

export function NftDetail(payload) {
  return (dispatch) => {
    dispatch(NftDetailInitiate())
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}nftDetail?nftId=${payload}`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(NftDetailSuccess(data))
          } else {
            dispatch(NftDetailFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(NftDetailFailure(err))
          reject(err)
        }),
    )
  }
}

//-----------------------------------Collection Nft LIST ---------------------------------------//

export const collectionNftListInitiate = () => ({
  type: nftAction.COLLECTION_NFT_LIST_INITIATE,
})

export const collectionNftListSuccess = (data) => ({
  type: nftAction.COLLECTION_NFT_LIST_SUCCESS,
  payload: data,
})

export const collectionNftListFailure = () => ({
  type: nftAction.COLLECTION_NFT_LIST_FAILURE,
})

export function collectionNftList(payload) {
  return (dispatch) => {
    dispatch(collectionNftListInitiate())
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}collectionNftList?pageNumber=${payload.pageCount}&collectionId=${payload.collectionId}`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(collectionNftListSuccess(data))
          } else {
            dispatch(collectionNftListFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(collectionNftListFailure(err))
          reject(err)
        }),
    )
  }
}
