import React from "react";
import { Button, Input, FormGroup, Label } from "reactstrap";
import Logoadmin from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";
import Header from '../../common-components/header/header'
import "./signup.scss";

const Home = () => {
  return (
    <>
      <div className="loginOuterBg">
        <div className="container">
          <Header />
          <div className="LoginOuter">
            <div className="loginLeft">
              <h2>enter the<br/>game sphere</h2>
            </div>
            <div className="LoginBg">
              <div className="loginBgInn">
                  <div className="loginHd">Register</div>
                  <form>
                    <div className="frmbx">
                      <FormGroup>
                        <label>Email</label>
                        <Input type="email" name="email" id="email"/>
                      </FormGroup>
                    </div>
                    <div className="frmbx">
                      <FormGroup>
                        <label>Password</label>
                        <Input type="password" name="password" id="password"/>
                      </FormGroup>
                    </div>
                    <div className="frmbx">
                      <FormGroup>
                        <label>Confirm Password</label>
                        <Input type="password" name="password" id="password"/>
                      </FormGroup>
                    </div>
                    <div className="frmbx">
                      <FormGroup>
                        <label>BTC Address ( Optional )</label>
                        <Input type="password" name="password" id="password"/>
                      </FormGroup>
                    </div>
                    <div className="loginbtn">
                      <Button>Sign up</Button>
                    </div>
                  </form>
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
