
import { usdPriceAction } from '../actionType'
import config from '../../config/config'
import axios from 'axios'
//----------------------------------- GET USD PRICE ---------------------------------------//

export const usdPriceInitiate = () => ({
    type: usdPriceAction.USD_PRICE_INITIATE,
  })
  
  export const usdPriceSuccess = (data) => ({
    type: usdPriceAction.USD_PRICE_SUCCESS,
    payload: data,
  })
  
  export const usdPriceFailure = () => ({
    type: usdPriceAction.USD_PRICE_FAILURE,
  })
  
  export function getUsdPrice(payload) {
    return (dispatch) => {
      dispatch(usdPriceInitiate())
      return new Promise((resolve, reject) =>
        axios
          .get(`${config.Url}getGspUsdPrice`,{
            headers: {}
          })
          .then((response) => {
            const data = response.data
            if (data.code && data.code == 200) {
              dispatch(usdPriceSuccess(data))
            } else {
              dispatch(usdPriceFailure(data))
            }
            resolve(data)
          })
          .catch((err) => {
            dispatch(usdPriceFailure(err))
            reject(err)
          }),
      )
    }
  }


  //----------------------------------- GET USD PRICE LIST ---------------------------------------//

export const usdPriceListInitiate = () => ({
  type: usdPriceAction.USD_PRICE_LIST_INITIATE,
})

export const usdPriceListSuccess = (data) => ({
  type: usdPriceAction.USD_PRICE_LIST_SUCCESS,
  payload: data,
})

export const usdPriceListFailure = () => ({
  type: usdPriceAction.USD_PRICE_LIST_FAILURE,
})

export function getUsdPriceList(payload) {
  return (dispatch) => {
    dispatch(usdPriceListInitiate())
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}getGspUsdPriceList`,{
          headers: {}
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(usdPriceListSuccess(data))
          } else {
            dispatch(usdPriceListFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(usdPriceListFailure(err))
          reject(err)
        }),
    )
  }
}