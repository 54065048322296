import React from "react";
import { Button } from 'reactstrap';
import {Link, NavLink} from 'react-router-dom';
import "./sidebar.scss";
import { MdOutlineDashboard } from "react-icons/md";
import { AiOutlineTransaction, AiOutlineUser } from "react-icons/ai";
import { RiExchangeDollarLine, RiLogoutBoxLine } from "react-icons/ri";
import { SiUnity } from "react-icons/si";
import { IoImageOutline } from "react-icons/io5"
import { FaGamepad, FaRegImages } from "react-icons/fa"

import { useAccount } from "wagmi";

import CustomButton from "../../pages/customButton/customButton";

const Sidebar = () => {
  const { address } = useAccount();
  console.log('address------>',address)
  const logOut = () => {
    localStorage.removeItem('adminToken');
    window.location.assign("./");
  }
  return (
    <>
      <div className="siderbarBg">
        <div className="sideNav">
          <ul>
            <li>
              <NavLink activeClassName='active' to="/dashboard"><MdOutlineDashboard /> Dashboard</NavLink>
            </li>
            <li>
              <NavLink to="/transfer-tokens"><AiOutlineTransaction /> Transfer Token</NavLink>
            </li>
            <li>
              <NavLink to='/transaction'><RiExchangeDollarLine /> Transactions</NavLink>
            </li>
            <li>
              <NavLink to="/user"><AiOutlineUser /> User </NavLink>
            </li>
            <li>
              <NavLink to="/nfts"><IoImageOutline /> NFTs</NavLink>
            </li>
            <li>
              <NavLink to="/collections"><FaRegImages /> Collections</NavLink>
            </li>
            <li>
              <NavLink to=""><FaGamepad /> Games</NavLink>
            </li>
          </ul>
        </div>
        {address ? <CustomButton /> : <Button onClick={logOut} className="logoutBtn"><RiLogoutBoxLine /> Logout</Button>}

      </div>
    </>
  );
};

export default Sidebar;
