import React, { useState, useEffect } from "react";
import { Button, Input, FormGroup, Label } from "reactstrap";
import Header from '../../common-components/header/header'
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import "./forget.scss";
import { adminForgetPassword } from "../../../redux/action/loginAction";
const initialState = {
    email: "",
    emailIdError: "",
    errorMsg: "",
};

const Forget = () => {
    const [iState, updateState] = useState(initialState);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { email, emailIdError, errorMsg } = iState;

    const handleChange = (e) => {
        const { name, value } = e.target;
        updateState({
            ...iState,
            [name]: value,
        });
    };

    let handleValidation = () => {
        let emailIdError = '';
        let formIsValid = true;

        if (!email) {
            emailIdError = 'Please enter email.';
            formIsValid = false
        }
        updateState({
            ...iState, emailIdError, errorMsg
        });
        return formIsValid

    }

    const handleSubmit = async (e) => {
        let formIsValid = handleValidation();
        if (formIsValid) {
            let data = { email }
            dispatch(adminForgetPassword(data))
                .then((res) => {
                    if (res.code == 200) {
                        localStorage.setItem('email', res.data.email)
                        localStorage.setItem('adminId', res.data._id)

                        toast.success(res.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        })
                        setTimeout(() => {
                            navigate('/verify-otp')
                        },2000)


                    } else {
                        toast.error(res.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        })
                        updateState({
                            ...iState,
                            errorMsg: res.message,
                            emailIdError: '',
                            passwordError: '',
                        })
                    }
                })
                .catch((err) => {
                    console.log(`error>>>> ${err}`)
                })
        }

    }
    return (
        <>
            <div className="loginOuterBg">
                <div className="container">
                    <ToastContainer />
                    <Header />

                    <div className="LoginOuter">
                        <div className="loginLeft">
                            <h2>enter the<br />game sphere</h2>
                        </div>
                        <div className="LoginBg">
                            <div className="loginBgInn">
                                <div className="loginHd">Forget Password</div>
                                <form>
                                    <div className="frmbx">
                                        <FormGroup>
                                            <label>Email</label>
                                            <Input
                                                type="email"
                                                name="email"
                                                value={email}
                                                onChange={handleChange}
                                                id="email"
                                                placeholder="Email"
                                            />
                                        </FormGroup>
                                    </div>
                                    <span style={{ color: 'red' }}>{emailIdError}</span>

                                    <div className="loginbtn">
                                        <Button onClick={handleSubmit}>Submit</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Forget;
