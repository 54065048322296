import { userAction } from "../actionType";

const initialState = {
  loader: false,
  userListData: [],
};
export default function user(state = initialState, { type, payload }) {
  switch (type) {
    case userAction.USER_LIST_INITIATE: {
      return {
        ...state,
        loader: true,
        userListData: [],
      };
    }
    case userAction.USER_LIST_SUCCESS: {
      return {
        ...state,
        loader: false,
        userListData: payload,
      };
    }
    case userAction.USER_LIST_FAILURE: {
      return {
        ...state,
        loader: false,
        userListData: [],
      };
    }
    default:
      return state;
  }
}
