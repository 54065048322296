import React, { useState, useEffect } from "react";
import Money from '../../../assets/images/moneys-1.png'
import { Button, FormGroup, Input } from 'reactstrap';
import WalletMoney from '../../../assets/images/wallet-money-1.png'
import shieldIcon from '../../../assets/images/Token_icon.png'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { useAccount, useSigner, useProvider, useContract } from "wagmi";
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import erc from '../../../smartContractFunction/abi.json'
import { transactionList } from "../../../redux/action/transactionAction";
import { userList } from "../../../redux/action/userAction";
import { getUsdPrice, getUsdPriceList } from "../../../redux/action/usdPriceAction";
import { FiDownload } from "react-icons/fi"
import { FaCalendarAlt } from "react-icons/fa"
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { TbRefresh } from "react-icons/tb"
import ReactLoading from 'react-loading';
import { CSVLink } from "react-csv";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';


const initialState = {
    tokenAddress: "",
    amount: "",
    burnAddress: "",
    burnAmount: "",
    searchUser: "",
    searchTrns: "",
    isFlag: false,
    errors: {},
};

const Rightsidebar = () => {
    const [iState, updateState] = useState(initialState);
    const [gspbalance, setGspbalance] = useState("");
    const [chain, setchain] = useState('');
    const [getbnbBalance, setGetbnbBalance] = useState("");
    const [popup, setPopup] = useState(false)
    const [burnPopup, setBurnPopup] = useState(false)
    const dispatch = useDispatch();
    const [perPage, SetcurrentPage] = useState(10)
    const [userPerPage, SetuserCurrentPage] = useState(10)
    const listData = useSelector((state) => state.transaction);
    const { transactionListData } = listData;
    const userLists = useSelector((state) => state.user);
    const { userListData } = userLists;
    const usdPrice = useSelector((state) => state.gspUsdPrice);
    const { usdPriceData, usdPriceList } = usdPrice;
    const { tokenAddress, amount, burnAddress, burnAmount, searchUser, searchTrns, isFlag, errors } = iState;
    const filename1 = 'transaction.csv';
    const filename = 'gamers.csv';
    let data = userListData.data && userListData.data[0].data.length > 0 ? userListData.data && userListData.data[0].data : []
    let data1 = transactionListData.data && transactionListData.data[0].data.length > 0 ? transactionListData.data && transactionListData.data[0].data : []
    const provider = useProvider();
    const { data: signer } = useSigner();

    const getChain = async () => {
        let aw = await provider.getNetwork()
        setchain(aw)
    }
    useEffect(() => {
        getChain()
    })
    let adrserc;
    if (chain.chainId === 80001) {
        adrserc = process.env.REACT_APP_ERC_POLYGON_ADDRESS
    } else {
        adrserc = process.env.REACT_APP_ERC_BSC_ADDRESS
    }
    const contract = useContract({
        address: adrserc,
        abi: erc,
        signerOrProvider: signer,
    });

    const getGspBalance = async () => {
        const bnbBalance = await provider.getBalance(signer && signer._address);
        let bnbBal = parseInt(bnbBalance._hex, 16) / 1000000000000000000;
        setGetbnbBalance(bnbBal);
        const approve = await contract.balanceOf(signer && signer._address);
        let gspBal = parseInt(approve._hex, 16) / 1000000000000000000;
        setGspbalance(gspBal);
    };
    let countTrnData = transactionListData && (transactionListData.data && transactionListData.data[0].total.length > 0) ? transactionListData.data[0].total[0].count / 10 : []
    let countUserData = userListData && (userListData.data && userListData.data[0].total.length > 0) ? userListData.data[0].total[0].count / 10 : []
    useEffect(() => {
        getGspBalance();
    }, [signer]);

    useEffect(() => {
        let chainId = chain.chainId === 80001 ? 80001 : 97
        let obj = { searchVal: '', pageNumber: 1, chainId }
        dispatch(transactionList(obj));
    }, [chain]);

    const handlePageChange = (e) => {
        const selectedPage = e.selected + 1
        SetcurrentPage(10 * selectedPage)
        let chainId = chain.chainId === 80001 ? 80001 : 97
        let obj = { searchVal: '', pageNumber: selectedPage, chainId }
        dispatch(transactionList(obj))
    }
    useEffect(() => {
        let obj = { searchVal: '', pageNumber: 1 }
        dispatch(userList(obj));
    }, [userList]);

    useEffect(() => {
        dispatch(getUsdPrice());
        dispatch(getUsdPriceList());
    }, []);


    const handleUserPageChange = (e) => {
        const selectedPage = e.selected + 1
        SetuserCurrentPage(10 * selectedPage)
        let obj = { searchVal: '', pageNumber: selectedPage }
        dispatch(userList(obj))
    }

    const walletAddressChange = (address) => {
        if (address) {
            let ar = address.split('')
            ar.splice(4, 34, ".....")
            return ar.join('')
        }

    }

    const transactionIdChange = (trId) => {
        if (trId) {
            let ar = trId.split('')
            ar.splice(4, 59, ".....")
            return ar.join('')
        }

    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        updateState({
            ...iState,
            [name]: value
        });

    }
    let handleValidationMint = () => {
        let tokenAddressError = "";
        let amountError = "";
        let formIsValid = true;

        if (!tokenAddress) {
            tokenAddressError = "Please enter address.";
            formIsValid = false;
        }
        if (!amount) {
            amountError = "Please enter amount.";
            formIsValid = false
        }
        updateState({
            ...iState, errors: { tokenAddressError, amountError, }
        });
        return formIsValid;
    };
    const mintBalance = async () => {
        let formIsValid = handleValidationMint()
        if (formIsValid) {
            const mintBal = await contract.mint(tokenAddress, (amount * 1000000000000000000).toString());
            if (mintBal) {
                setPopup(false)
                updateState({
                    ...iState,
                    isFlag: true,
                    tokenAddress: "",
                    amount: "",
                    errors: {}
                });
                setTimeout(() => {
                    toast.success("Token mint successfully", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    getGspBalance();
                    updateState({
                        ...iState,
                        isFlag: false,
                        tokenAddress: "",
                        amount: "",
                        errors: {}
                    });
                }, 15000);

            }
        }


    };

    let handleValidationBurn = () => {
        let burnAddressError = "";
        let burnAmountError = "";
        let formIsValid = true;

        if (!burnAddress) {
            burnAddressError = "Please enter address.";
            formIsValid = false;
        }
        if (!burnAmount) {
            burnAmountError = "Please enter amount.";
            formIsValid = false;
        }
        updateState({
            ...iState,
            errors: { burnAddressError, burnAmountError, }
        });
        return formIsValid;
    };
    const burnBalance = async () => {
        let formIsValid = handleValidationBurn()
        if (formIsValid) {
            const burnBal = await contract.burn(burnAddress, (burnAmount * 1000000000000000000).toString());
            if (burnBalance) {
                setBurnPopup(false)
                updateState({
                    ...iState,
                    isFlag: true,
                    burnAddress: "",
                    burnAmount: "",
                    errors: {}
                });
                setTimeout(() => {
                    toast.success("Token burn successfully", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    getGspBalance();
                    updateState({
                        ...iState,
                        isFlag: false,
                        burnAddress: "",
                        burnAmount: "",
                        errors: {}
                    });
                }, 15000);

            }
        }

    };

    const openModal = () => {
        setPopup(true)
    }
    const handleClose = () => {
        setPopup(false)
    }

    const openBurnModal = () => {
        setBurnPopup(true)
    }
    const handleBurnClose = () => {
        setBurnPopup(false)
    }

    const fromAddressChange = (fromAddress) => {
        if (fromAddress) {
            let ar = fromAddress.split('')
            ar.splice(4, 34, ".....")
            return ar.join('')
        }

    }
    const handleTrnsSearch = (e) => {
        const { name, value } = e.target;
        updateState({
            ...iState,
            [name]: value
        });
        let chainId = chain.chainId === 80001 ? 80001 : 97
        let obj = { searchVal: value, pageNumber: 1, chainId }
        dispatch(transactionList(obj));
    }
    const handleUserSearch = (e) => {
        const { name, value } = e.target;
        updateState({
            ...iState,
            [name]: value
        });
        let obj = { searchVal: value, pageNumber: 1 }
        dispatch(userList(obj));
    }


    const handleEvent = (event, picker) => {
        console.log(picker.startDate);
    }
    const handleCallback = (start, end) => {
        let fromDate = moment(start._d).format("YYYY-MM-DD")
        let toDate = moment(end._d).format("YYYY-MM-DD")
        let chainId = chain.chainId === 80001 ? 80001 : 97
        let obj = { searchVal: '', pageNumber: 1, fromDate, toDate, chainId }
        dispatch(transactionList(obj));
    }
    const refreshTrnsPage = () => {
        let chainId = chain.chainId === 80001 ? 80001 : 97
        let obj = { searchVal: '', pageNumber: 1, chainId }
        dispatch(transactionList(obj));
        updateState({ ...iState, searchTrns: "" })
    }

    const refreshUserPage = () => {
        let obj = { searchVal: '', pageNumber: 1 }
        dispatch(userList(obj));
        updateState({ ...iState, searchUser: "" })
    }


    return (
        <>
            <div className="dashRightside">
                <ToastContainer />
                <div className="dashRightBx">
                    <div className="dashR_L">
                        <div className="graphBx_Outer">
                            <div className="walletbalanceBx">
                                <i><img src={Money} alt='' /></i>
                                <span>Wallet Balance</span>
                                <strong>$ {usdPriceData.data && (usdPriceData.data.price * gspbalance).toFixed(4)}</strong>
                                <small><img src={shieldIcon} alt='' /> {gspbalance} GSP</small>
                            </div>
                            <div className="walletbalanceBx">
                                <i><img src={WalletMoney} alt='' /></i>
                                <span>Wallet Balance</span>
                                <strong>{getbnbBalance && getbnbBalance.toFixed(5)} {chain.chainId === 80001 ? "Matic" : "BNB"} </strong>
                                <small>USD: 127,321,000</small>
                            </div>
                            <div className="balanceHd">
                                <span><img src={shieldIcon} alt='' /> GSP/USD</span>
                                <strong>$ {usdPriceData.data && usdPriceData.data.price}</strong>
                                <a href={`${chain.chainId === 80001
                                    ? `https://mumbai.polygonscan.com/address/${contract.address}`
                                    : `https://testnet.bscscan.com/address/${contract.address}`
                                    }`}
                                    target="_blank"
                                >
                                    <small>{contract && contract.address}</small>
                                </a>
                                {/* <a href={chain.chainId === 80001 ?{`https://mumbai.polygonscan.com/address/${contract.address}`}:{` https://testnet.bscscan.com/address/${contract.address}`}} target="_blank"><small> {contract && contract.address}</small></a> */}
                            </div>
                            <div className="chartBx">
                                <LineChart width={600} height={300} data={usdPriceList && usdPriceList.data && usdPriceList.data}>
                                    <XAxis dataKey="time" />
                                    <YAxis />
                                    <CartesianGrid stroke="" strokeDasharray="5" />
                                    <Line type="monotone" dataKey="price" stroke="#8884d8" />
                                    <Tooltip />
                                </LineChart>



                            </div>
                            <div className="mintokenOuter">
                                <div className="mintoken">
                                    <strong>Mint Token</strong>
                                    <Link onClick={openModal}><small>Mint</small></Link>
                                    {
                                        isFlag ?
                                            <div className="loaderOuter" >
                                                <ReactLoading type="bars" className="loaderInn" />
                                            </div> : ""
                                    }
                                </div>
                                <div className="mintoken">
                                    <strong>Burn Token</strong>

                                    <Link onClick={openBurnModal}><small>Burn</small></Link>
                                </div>
                            </div>
                        </div>
                        <div className="transBg">
                            <div className="transUserOuter">
                                <div className="transHd">Gamers Transaction</div>
                                <div className="transFltr">
                                    <div className="transId">
                                    </div>
                                    <div className="downBtn">
                                        <CSVLink data={data1} filename={filename1}>
                                            <Button><FiDownload /> </Button>
                                        </CSVLink>
                                    </div>
                                    <div className="dateInpBx">
                                        <DateRangePicker
                                            onEvent={handleEvent} onCallback={handleCallback}
                                        >
                                            <input type="text" className="dateInp" />
                                        </DateRangePicker>
                                        <FaCalendarAlt />
                                    </div>
                                    <div className="srchBx">
                                        <FormGroup>
                                            <Input type="text" name="searchTrns" id="exampleEmail" placeholder="Search..." value={searchTrns} onChange={handleTrnsSearch} />
                                        </FormGroup>
                                    </div>
                                    <div className="refreshBtn">
                                        <Button onClick={refreshTrnsPage}><TbRefresh /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="tableBx">
                                <table cellPadding="0" cellSpacing="0" border="0">
                                    <thead>
                                        <tr>
                                            <th>Sr. No.</th>
                                            <th>Tx. ID</th>
                                            <th>Date</th>
                                            <th>Value</th>
                                            <th>Value</th>
                                            <th>From</th>
                                            <th>To</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            transactionListData.data &&
                                                transactionListData.data[0].data.length > 0 ? (
                                                transactionListData.data[0].data.map((data, i) => (
                                                    <tr key={i}>
                                                        <td>{i + 1 + (perPage - 10)}</td>
                                                        <td>   <a href={`${data.chainId === 80001
                                                            ? `https://mumbai.polygonscan.com/tx/${data.hash}`
                                                            : `https://testnet.bscscan.com/tx/${data.hash}`
                                                            }`}
                                                            target="_blank"
                                                        >{transactionIdChange(data.hash)}
                                                        </a></td>

                                                        <td>{moment(data.createdAt).format("DD/MM/YYYY")}</td>
                                                        <td>{data.bnbValue && data.bnbValue.toFixed(4)} Matic</td>
                                                        <td>{data.gspValue} GSP</td>
                                                        <td>{fromAddressChange(data.from)}</td>
                                                        <td>{fromAddressChange(data.to)}</td>
                                                        <td>
                                                            <span className="approved">{data.status}</span>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr><td>Data not found</td></tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="paginationBx">
                                <ReactPaginate
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={Math.ceil(countTrnData)}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageChange}
                                    containerClassName={'pagination'}
                                    activeClassName={'active'}
                                />
                            </div>
                        </div>
                        <div className="transBg">
                            <div className="transUserOuter">
                                <div className="transHd">Gamers</div>
                                <div className="transFltr">
                                    <div className="daterange">

                                    </div>
                                    <div className="downBtn">
                                        <CSVLink data={data} filename={filename}>
                                            <Button><FiDownload /> </Button>
                                        </CSVLink>
                                    </div>
                                    <div className="srchBx">
                                        <FormGroup>
                                            <Input type="text" name="searchUser" id="exampleEmail" placeholder="Search..." value={searchUser} onChange={handleUserSearch} />
                                        </FormGroup>
                                    </div>
                                    <div className="refreshBtn">
                                        <Button onClick={refreshUserPage}><TbRefresh /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="tableBx">
                                <table cellPadding="0" cellSpacing="0" border="0">
                                    <thead>
                                        <tr>
                                            <th>Sr. No.</th>
                                            <th>User id</th>
                                            <th> Balance</th>
                                            <th> Balance</th>
                                            <th>Login Time</th>

                                        </tr>
                                    </thead>
                                    <tbody>


                                        {userListData.data && userListData.data[0].data.length > 0 ? (
                                            userListData.data[0].data.map((data, i) => (
                                                <tr key={i}>
                                                    <td>{i + 1 + (userPerPage - 10)}</td>
                                                    <td>{walletAddressChange(data.address)}</td>
                                                    <td>{data.ethBalance && data.ethBalance.toFixed(4)} {chain.chainId === 80001 ? "Matic" : "BNB"}</td>
                                                    <td>{data.gspBalance && data.gspBalance.toFixed(4)} GSP</td>
                                                    <td>{moment(data.updatedAt).format("DD/MM/YYYY HH:mm A")}</td>

                                                </tr>
                                            ))
                                        ) : (
                                            <tr><td>Data not found</td></tr>
                                        )}


                                    </tbody>
                                </table>
                            </div>
                            <div className="paginationBx">
                                <ReactPaginate
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={Math.ceil(countUserData)}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handleUserPageChange}
                                    containerClassName={'pagination'}
                                    activeClassName={'active'}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="dashR_R">
                        <div className="gamesBx">
                            <div className="gamesInn">
                                <span>Games:</span>
                                <strong>0</strong>
                            </div>
                            <div className="gamesInn">
                                <span>Gamers:</span>
                                <strong>{userListData && (userListData.data && userListData.data[0].total.length > 0) ? userListData.data[0].total[0].count : 0}</strong>
                            </div>
                        </div>
                        <div className="yourgames">
                            <div className="gamesHd">Your Games</div>
                            <div className="gameList">
                                <div className="gamesLeft">
                                    <strong>Super Cat</strong>
                                    <span>Innovative Cat Game....</span>
                                </div>
                                <div className="gamesRight">
                                    $GSP 0.00
                                </div>
                            </div>
                            <div className="gameList">
                                <div className="gamesLeft">
                                    <strong>Game 1</strong>
                                    <span>Some info about</span>
                                </div>
                                <div className="gamesRight">
                                    $GSP 0.00
                                </div>
                            </div>
                            <div className="gameList">
                                <div className="gamesLeft">
                                    <strong>Game 1</strong>
                                    <span>Some info about</span>
                                </div>
                                <div className="gamesRight">
                                    $GSP 0.00
                                </div>
                            </div>
                            <div className="gameList">
                                <div className="gamesLeft">
                                    <strong>Game 1</strong>
                                    <span>Some info about</span>
                                </div>
                                <div className="gamesRight">
                                    $GSP 0.00
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Modal show={popup} className="modalOuter" onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Mint Token</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label>Address</label>
                        <div className="gspFild">
                            <div className="gspInn_Left">
                                <input
                                    type="text"
                                    name="tokenAddress"
                                    placeholder="address"
                                    value={tokenAddress}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <span style={{ color: "red" }}>{errors.tokenAddressError}</span>
                        </div>

                        <label>Amount</label>
                        <div className="gspFild">
                            <div className="gspInn_Left">
                                <input
                                    type="number"
                                    name="amount"
                                    placeholder="Amount"
                                    value={amount}
                                    onChange={handleInputChange}

                                />
                            </div>
                            <span style={{ color: 'red' }}>{errors.amountError}</span>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <button variant="primary" onClick={mintBalance}>Save</button>
                    </Modal.Footer>
                </Modal>

                <Modal show={burnPopup} className="modalOuter" onHide={handleBurnClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Burn Token</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label>Address</label>
                        <div className="gspFild">
                            <div className="gspInn_Left">
                                <input
                                    type="text"
                                    name="burnAddress"
                                    placeholder="address"
                                    value={burnAddress}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <span style={{ color: 'red' }}>{errors.burnAddressError}</span>
                        </div>

                        <label>Amount</label>
                        <div className="gspFild">
                            <div className="gspInn_Left">
                                <input
                                    type="number"
                                    name="burnAmount"
                                    placeholder="Amount"
                                    value={burnAmount}
                                    onChange={handleInputChange}

                                />
                            </div>
                            <span style={{ color: 'red' }}>{errors.burnAmountError}</span>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <button variant="primary" onClick={burnBalance}>Save</button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
};

export default Rightsidebar;
