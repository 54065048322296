import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import Token from "../../../assets/images/Token.png"
import SlideImg from "../../../assets/images/slide-img.png"
import { AiOutlineHeart } from "react-icons/ai";
import { Link, useNavigate } from 'react-router-dom'
import { NftList } from "../../../redux/action/nftAction";

const Rightsidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [pageCount, setPageCount] = useState(5)
    const [category, setCategory] = useState();
    const listData = useSelector((state) => state.NFT);
    const { loader, nftListData } = listData;

    useEffect(() => {
        dispatch(NftList(pageCount));
    }, [NftList]);

    const nftDetail = (nftId) => {
        localStorage.setItem('nftId', nftId)
        navigate('/nfts-details')

    }
    const handleLoadMore = () => {
        setPageCount(pageCount + 5)
        dispatch(NftList(pageCount + 5));
    }

    const handleSearch = (e) => {
        const { name, value } = e.target;
        let obj = {
            category: value,
            pageCount,
        }
        setCategory(value)
        dispatch(NftList(obj));

    };

    return (
        <>
            <div className="dashRightside">
                <div className="nftsOuterBx">
                    <div className="nftsFltr">
                        <h2>NFTs</h2>
                        <div className="fltrBx">
                            <span>
                                <Label>Category:</Label>
                                <Input type="select" name="category" value={category} onChange={handleSearch} className="selInp">
                                    <option>Select category</option>
                                    <option value="Cricket">Cricket</option>
                                    <option value="Hockey">Hockey</option>
                                    <option value="Football">Football</option>
                                    <option value="Volleyball">Volleyball</option>

                                </Input>

                            </span>
                            <span>
                                <Label>Collection:</Label>
                                <Input type="select" name="select">
                                    <option>All</option>
                                </Input>
                            </span>
                            <Link to='/create-nfts'> <Button className="createBtn">Create</Button></Link>
                        </div>
                    </div>
                    <div className="listOutBx">


                        {nftListData && nftListData.data &&
                            nftListData.data.length > 0 ? (
                            nftListData.data.map((item, i) => (
                                <div class="slideItemBx">
                                    <div className='slideImg' onClick={() => nftDetail(item._id)}>
                                        <Link><img src={item.nftImage} alt="" /></Link>

                                    </div>
                                    <div className='slideTitle'>
                                        <strong>{item.nftName} #12345</strong>
                                        <span>{item.collectionName}</span>
                                    </div>
                                    <div className='likeOuter'>
                                        <div className='likeLeft'>
                                            <img src={Token} alt="" />
                                            {item.price ? item.price : 0}
                                        </div>
                                        <div className='likeright'>
                                            <AiOutlineHeart />
                                            3.2k
                                        </div>
                                    </div>
                                </div>

                            ))
                        ) : (
                            <p> Data not found</p>

                        )
                        }


                        {/* <div class="slideItemBx">
                            <div className='slideImg'>
                                <Link to="/nfts-details"><img src={SlideImg} alt="" /></Link>
                            </div>
                            <div className='slideTitle'>
                                <strong>GameSphere #12345</strong>
                                <span>Dark Render In The Pink</span>
                            </div>
                            <div className='likeOuter'>
                                <div className='likeLeft'>
                                    <img src={Token} alt="" />
                                    0.5
                                </div>
                                <div className='likeright'>
                                    <AiOutlineHeart />
                                    3.2k
                                </div>
                            </div>
                        </div>
                        <div class="slideItemBx">
                            <div className='slideImg'>
                                <Link to="/nfts-details"><img src={SlideImg} alt="" /></Link>
                            </div>
                            <div className='slideTitle'>
                                <strong>GameSphere #12345</strong>
                                <span>Dark Render In The Pink</span>
                            </div>
                            <div className='likeOuter'>
                                <div className='likeLeft'>
                                    <img src={Token} alt="" />
                                    0.5
                                </div>
                                <div className='likeright'>
                                    <AiOutlineHeart />
                                    3.2k
                                </div>
                            </div>
                        </div>
                        <div class="slideItemBx">
                            <div className='slideImg'>
                                <Link to="/nfts-details"><img src={SlideImg} alt="" /></Link>
                            </div>
                            <div className='slideTitle'>
                                <strong>GameSphere #12345</strong>
                                <span>Dark Render In The Pink</span>
                            </div>
                            <div className='likeOuter'>
                                <div className='likeLeft'>
                                    <img src={Token} alt="" />
                                    0.5
                                </div>
                                <div className='likeright'>
                                    <AiOutlineHeart />
                                    3.2k
                                </div>
                            </div>
                        </div>
                        <div class="slideItemBx">
                            <div className='slideImg'>
                                <Link to="/nfts-details"><img src={SlideImg} alt="" /></Link>
                            </div>
                            <div className='slideTitle'>
                                <strong>GameSphere #12345</strong>
                                <span>Dark Render In The Pink</span>
                            </div>
                            <div className='likeOuter'>
                                <div className='likeLeft'>
                                    <img src={Token} alt="" />
                                    0.5
                                </div>
                                <div className='likeright'>
                                    <AiOutlineHeart />
                                    3.2k
                                </div>
                            </div>
                        </div>

                        <div class="slideItemBx">
                            <div className='slideImg'>
                                <Link to="/nfts-details"><img src={SlideImg} alt="" /></Link>
                            </div>
                            <div className='slideTitle'>
                                <strong>GameSphere #12345</strong>
                                <span>Dark Render In The Pink</span>
                            </div>
                            <div className='likeOuter'>
                                <div className='likeLeft'>
                                    <img src={Token} alt="" />
                                    0.5
                                </div>
                                <div className='likeright'>
                                    <AiOutlineHeart />
                                    3.2k
                                </div>
                            </div>
                        </div>
                        <div class="slideItemBx">
                            <div className='slideImg'>
                                <Link to="/nfts-details"><img src={SlideImg} alt="" /></Link>
                            </div>
                            <div className='slideTitle'>
                                <strong>GameSphere #12345</strong>
                                <span>Dark Render In The Pink</span>
                            </div>
                            <div className='likeOuter'>
                                <div className='likeLeft'>
                                    <img src={Token} alt="" />
                                    0.5
                                </div>
                                <div className='likeright'>
                                    <AiOutlineHeart />
                                    3.2k
                                </div>
                            </div>
                        </div>
                        <div class="slideItemBx">
                            <div className='slideImg'>
                                <Link to="/nfts-details"><img src={SlideImg} alt="" /></Link>
                            </div>
                            <div className='slideTitle'>
                                <strong>GameSphere #12345</strong>
                                <span>Dark Render In The Pink</span>
                            </div>
                            <div className='likeOuter'>
                                <div className='likeLeft'>
                                    <img src={Token} alt="" />
                                    0.5
                                </div>
                                <div className='likeright'>
                                    <AiOutlineHeart />
                                    3.2k
                                </div>
                            </div>
                        </div>
                        <div class="slideItemBx">
                            <div className='slideImg'>
                                <Link to="/nfts-details"><img src={SlideImg} alt="" /></Link>
                            </div>
                            <div className='slideTitle'>
                                <strong>GameSphere #12345</strong>
                                <span>Dark Render In The Pink</span>
                            </div>
                            <div className='likeOuter'>
                                <div className='likeLeft'>
                                    <img src={Token} alt="" />
                                    0.5
                                </div>
                                <div className='likeright'>
                                    <AiOutlineHeart />
                                    3.2k
                                </div>
                            </div>
                        </div>
                        <div class="slideItemBx">
                            <div className='slideImg'>
                                <Link to="/nfts-details"><img src={SlideImg} alt="" /></Link>
                            </div>
                            <div className='slideTitle'>
                                <strong>GameSphere #12345</strong>
                                <span>Dark Render In The Pink</span>
                            </div>
                            <div className='likeOuter'>
                                <div className='likeLeft'>
                                    <img src={Token} alt="" />
                                    0.5
                                </div>
                                <div className='likeright'>
                                    <AiOutlineHeart />
                                    3.2k
                                </div>
                            </div>
                        </div>
                        <div class="slideItemBx">
                            <div className='slideImg'>
                                <Link to="/nfts-details"><img src={SlideImg} alt="" /></Link>
                            </div>
                            <div className='slideTitle'>
                                <strong>GameSphere #12345</strong>
                                <span>Dark Render In The Pink</span>
                            </div>
                            <div className='likeOuter'>
                                <div className='likeLeft'>
                                    <img src={Token} alt="" />
                                    0.5
                                </div>
                                <div className='likeright'>
                                    <AiOutlineHeart />
                                    3.2k
                                </div>
                            </div>
                        </div>
                        <div class="slideItemBx">
                            <div className='slideImg'>
                                <Link to="/nfts-details"><img src={SlideImg} alt="" /></Link>
                            </div>
                            <div className='slideTitle'>
                                <strong>GameSphere #12345</strong>
                                <span>Dark Render In The Pink</span>
                            </div>
                            <div className='likeOuter'>
                                <div className='likeLeft'>
                                    <img src={Token} alt="" />
                                    0.5
                                </div>
                                <div className='likeright'>
                                    <AiOutlineHeart />
                                    3.2k
                                </div>
                            </div>
                        </div>
                        <div class="slideItemBx">
                            <div className='slideImg'>
                                <Link to="/nfts-details"><img src={SlideImg} alt="" /></Link>
                            </div>
                            <div className='slideTitle'>
                                <strong>GameSphere #12345</strong>
                                <span>Dark Render In The Pink</span>
                            </div>
                            <div className='likeOuter'>
                                <div className='likeLeft'>
                                    <img src={Token} alt="" />
                                    0.5
                                </div>
                                <div className='likeright'>
                                    <AiOutlineHeart />
                                    3.2k
                                </div>
                            </div>
                        </div>
                        <div class="slideItemBx">
                            <div className='slideImg'>
                                <Link to="/nfts-details"><img src={SlideImg} alt="" /></Link>
                            </div>
                            <div className='slideTitle'>
                                <strong>GameSphere #12345</strong>
                                <span>Dark Render In The Pink</span>
                            </div>
                            <div className='likeOuter'>
                                <div className='likeLeft'>
                                    <img src={Token} alt="" />
                                    0.5
                                </div>
                                <div className='likeright'>
                                    <AiOutlineHeart />
                                    3.2k
                                </div>
                            </div>
                        </div>
                        <div class="slideItemBx">
                            <div className='slideImg'>
                                <Link to="/nfts-details"><img src={SlideImg} alt="" /></Link>
                            </div>
                            <div className='slideTitle'>
                                <strong>GameSphere #12345</strong>
                                <span>Dark Render In The Pink</span>
                            </div>
                            <div className='likeOuter'>
                                <div className='likeLeft'>
                                    <img src={Token} alt="" />
                                    0.5
                                </div>
                                <div className='likeright'>
                                    <AiOutlineHeart />
                                    3.2k
                                </div>
                            </div>
                        </div>
                        <div class="slideItemBx">
                            <div className='slideImg'>
                                <Link to="/nfts-details"><img src={SlideImg} alt="" /></Link>
                            </div>
                            <div className='slideTitle'>
                                <strong>GameSphere #12345</strong>
                                <span>Dark Render In The Pink</span>
                            </div>
                            <div className='likeOuter'>
                                <div className='likeLeft'>
                                    <img src={Token} alt="" />
                                    0.5
                                </div>
                                <div className='likeright'>
                                    <AiOutlineHeart />
                                    3.2k
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="moreList">
                        <Button onClick={handleLoadMore}>Load More</Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Rightsidebar;
