import { combineReducers } from "redux";
import transaction from "./reducer/transactionReducer";
import user from "./reducer/userReducer";
import gspUsdPrice from "./reducer/usdPriceReducer";
import tokenTransaction from "./reducer/transactionTokenReducer";
import collection from "./reducer/collectionReducer";
import NFT from "./reducer/nftReducer";

const rootReducer = combineReducers({
  transaction,
  user,
  gspUsdPrice,
  tokenTransaction,
  collection,
  NFT
});

export default rootReducer;
