import React from 'react'
import "./App.scss";
import { Web3Modal,Web3NetworkSwitch } from "@web3modal/react";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import {
  EthereumClient,
  modalConnectors,
  walletConnectProvider,
} from "@web3modal/ethereum";
import { bscTestnet,polygonMumbai } from "wagmi/chains";
import Layout from './layout/layout';
const chains = [bscTestnet,polygonMumbai];


const App = () => {

  // Wagmi client
  const { provider } = configureChains(chains, [
    walletConnectProvider({ projectId: "58b6c736381980f9756f7a4933dc0b2a" }),
  ]);
  const wagmiClient = createClient({
    autoConnect: true,
    connectors: modalConnectors({
      projectId: "58b6c736381980f9756f7a4933dc0b2a",
      version: "1", // or "2"
      appName: "GameSphere Crypto Token",
      chains,
    }),
    provider,
  });
  // Web3Modal Ethereum Client
  const ethereumClient = new EthereumClient(wagmiClient, chains);
  return (
    <div>
      <WagmiConfig client={wagmiClient}>
      {/* <Web3NetworkSwitch /> */}
        <Layout />
      </WagmiConfig>
      <Web3Modal
        projectId="58b6c736381980f9756f7a4933dc0b2a"
        ethereumClient={ethereumClient}
      />
    </div>

  )
}

export default App
