import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import Token from "../../../assets/images/Token.png"
import SlideImg from "../../../assets/images/collection-img.png"
import { AiOutlineHeart } from "react-icons/ai";
import { useAccount } from "wagmi";
import CustomButton from "../customButton/customButton";
import { Link, useNavigate } from 'react-router-dom'
import { collectionList } from "../../../redux/action/collectionAction";

const Rightsidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [pageCount, setPageCount] = useState(5)
    const [category, setCategory] = useState("")
    const { address } = useAccount();
    const listData = useSelector((state) => state.collection);
    const { collectionListData } = listData;

    useEffect(() => {
        let obj = {
            category: category,
            pageCount: pageCount,
        }
        dispatch(collectionList(obj));
    }, [collectionList]);

    const collectionDetail = (collectionId) => {
        localStorage.setItem('collectionId', collectionId)
        navigate('/collections-details')

    }

    const handleLoadMore = () => {
        setPageCount(pageCount + 5)
        let obj = {
            category: category,
            pageCount: pageCount + 5,
        }
        dispatch(collectionList(obj));
    }

    const handleSearch = (e) => {
        const { name, value } = e.target;
        let obj = {
            category: value,
            pageCount,
        }
        setCategory(value)
        dispatch(collectionList(obj));

    };


    return (
        <>
            <div className="dashRightside">
                <div className="nftsOuterBx">
                    <div className="nftsFltr">
                        <h2>Collections</h2>
                        <div className="fltrBx">
                            <span>
                                <Label>Category:</Label>
                                <Input type="select" name="category" value={category} onChange={handleSearch} className="selInp">
                                    <option>Select Cotegory</option>
                                    <option value="Cricket">Cricket</option>
                                    <option value="Hockey">Hockey</option>
                                    <option value="Football">Football</option>
                                    <option value="Volleyball">Volleyball</option>

                                </Input>
                            </span>

                            {address ? <Link to="/create-collections"> <Button className="createBtn">Create</Button></Link> : <Link to="/create-collections"> <Button className="createBtn">Create</Button></Link>}


                        </div>
                    </div>
                    <div className="listOutBx">


                        {collectionListData && collectionListData.data &&
                            collectionListData.data.length > 0 ? (
                            collectionListData.data.map((item, i) => (
                                <div class="collectionItemBx">
                                    <div className='slideImg' onClick={() => collectionDetail(item._id)}>
                                        <Link> <img src={item.collectionImage} alt="" /></Link>

                                    </div>
                                    <div className="collectName">{item.collectionName}</div>
                                    <div className="floorBx">
                                        <span>Floor</span>
                                        <strong>{item.florPrice} GSP</strong>
                                    </div>
                                    <div className="floorBx">
                                        <span>Total Volume</span>
                                        <strong>{item.totalVolumes} GSP</strong>
                                    </div>
                                </div>

                            ))
                        ) : (
                            <p> Data not found</p>

                        )
                        }

                    </div>
                    <div className="moreList">
                        <Button onClick={handleLoadMore}>Load More</Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Rightsidebar;
