export const loginAction = {
  ADMIN_LOGIN_INITIATE: "ADMIN_LOGIN_INITIATE",
  ADMIN_LOGIN_SUCCESS: "ADMIN_LOGIN_SUCCESS",
  ADMIN_LOGIN_FAILURE: "ADMIN_LOGIN_FAILURE",
  ADMIN_FORGET_PASSWORD_INITIATE: "ADMIN_FORGET_PASSWORD_INITIATE",
  ADMIN_FORGET_PASSWORD_SUCCESS: "ADMIN_FORGET_PASSWORD_SUCCESS",
  ADMIN_FORGET_PASSWORD_FAILURE: "ADMIN_FORGET_PASSWORD_FAILURE",
  ADMIN_VERIFYOTP_INITIATE: "ADMIN_VERIFYOTP_INITIATE",
  ADMIN_VERIFYOTP_SUCCESS: "ADMIN_VERIFYOTP_SUCCESS",
  ADMIN_VERIFYOTP_FAILURE: "ADMIN_VERIFYOTP_FAILURE",
  ADMIN_RESENDOTP_INITIATE: "ADMIN_RESENDOTP_INITIATE",
  ADMIN_RESENDOTP_SUCCESS: "ADMIN_RESENDOTP_SUCCESS",
  ADMIN_RESENDOTP_FAILURE: "ADMIN_RESENDOTP_FAILURE",
  ADMIN_RESET_PASSWORD_INITIATE: "ADMIN_RESET_PASSWORD_INITIATE",
  ADMIN_RESET_PASSWORD_SUCCESS: "ADMIN_RESET_PASSWORD_SUCCESS",
  ADMIN_RESET_PASSWORD_FAILURE: "ADMIN_RESET_PASSWORD_FAILURE",
};

export const connectWalletAction = {
  ADMIN_CONNECTWALLET_INITIATE: "ADMIN_CONNECTWALLET_INITIATE",
  ADMIN_CONNECTWALLET_SUCCESS: "ADMIN_CONNECTWALLET_SUCCESS",
  ADMIN_CONNECTWALLET_FAILURE: "ADMIN_CONNECTWALLET_FAILURE",
};

export const transactionAction = {
  TRANSACTION_LIST_INITIATE: "TRANSACTION_LIST_INITIATE",
  TRANSACTION_LIST_SUCCESS: "TRANSACTION_LIST_SUCCESS",
  TRANSACTION_LIST_FAILURE: "TRANSACTION_LIST_FAILURE",
};

export const userAction = {
  USER_LIST_INITIATE: "USER_LIST_INITIATE",
  USER_LIST_SUCCESS: "USER_LIST_SUCCESS",
  USER_LIST_FAILURE: "USER_LIST_FAILURE",
};

export const usdPriceAction = {
  USD_PRICE_INITIATE: "USD_PRICE_INITIATE",
  USD_PRICE_SUCCESS: "USD_PRICE_SUCCESS",
  USD_PRICE_FAILURE: "USD_PRICE_FAILURE",

  USD_PRICE_LIST_INITIATE: "USD_PRICE_LIST_INITIATE",
  USD_PRICE_LIST_SUCCESS: "USD_PRICE_LIST_SUCCESS",
  USD_PRICE_LIST_FAILURE: "USD_PRICE_LIST_FAILURE",
};


export const transactionTokenAction = {
  TRANSACTION_TOKEN_ADD_INITIATE: "TRANSACTION_TOKEN_ADD_INITIATE",
  TRANSACTION_TOKEN_ADD_SUCCESS: "TRANSACTION_TOKEN_ADD_SUCCESS",
  TRANSACTION_TOKEN_ADD_FAILURE: "TRANSACTION_TOKEN_ADD_FAILURE",
  TRANSACTION_TOKEN_LIST_INITIATE: "TRANSACTION_TOKEN_LIST_INITIATE",
  TRANSACTION_TOKEN_LIST_SUCCESS: "TRANSACTION_TOKEN_LIST_SUCCESS",
  TRANSACTION_TOKEN_LIST_FAILURE: "TRANSACTION_TOKEN_LIST_FAILURE",
};

export const collectionAction = {
  CREATE_COLLECTION_INITIATE: "CREATE_COLLECTION_INITIATE",
  CREATE_COLLECTION_SUCCESS: "CREATE_COLLECTION_SUCCESS",
  CREATE_COLLECTION_FAILURE: "CREATE_COLLECTION_FAILURE",
  COLLECTION_LIST_INITIATE: "COLLECTION_LIST_INITIATE",
  COLLECTION_LIST_SUCCESS: "COLLECTION_LIST_SUCCESS",
  COLLECTION_LIST_FAILURE: "COLLECTION_LIST_FAILURE",
  COLLECTION_DETAIL_INITIATE: "COLLECTION_DETAIL_INITIATE",
  COLLECTION_DETAIL_SUCCESS: "COLLECTION_DETAIL_SUCCESS",
  COLLECTION_DETAIL_FAILURE: "COLLECTION_DETAIL_FAILURE",
};

export const nftAction = {
  CREATE_NFT_INITIATE: "CREATE_NFT_INITIATE",
  CREATE_NFT_SUCCESS: "CREATE_NFT_SUCCESS",
  CREATE_NFT_FAILURE: "CREATE_NFT_FAILURE",
  NFT_LIST_INITIATE: "NFT_LIST_INITIATE",
  NFT_LIST_SUCCESS: "NFT_LIST_SUCCESS",
  NFT_LIST_FAILURE: "NFT_LIST_FAILURE",
  NFT_DETAIL_INITIATE: "NFT_DETAIL_INITIATE",
  NFT_DETAIL_SUCCESS: "NFT_DETAIL_SUCCESS",
  NFT_DETAIL_FAILURE: "NFT_DETAIL_FAILURE",
  COLLECTION_NFT_LIST_INITIATE: "COLLECTION_NFT_LIST_INITIATE",
  COLLECTION_NFT_LIST_SUCCESS: "COLLECTION_NFT_LIST_SUCCESS",
  COLLECTION_NFT_LIST_FAILURE: "COLLECTION_NFT_LIST_FAILURE",
};

