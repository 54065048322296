import React, { useState, useEffect } from "react";
import HeaderAfterLogin from "../../common-components/header-afterlogin/header-afterlogin";
import SideBar from '../../common-components/sidebar/sidebar';
import Tokensidebar from './tokens-sidebar';
import "./transfer-tokens.scss";


const TransferTokens = () => {

  return (
    <>
      <div className="mainBg">
        <div className="dash_container">
          <HeaderAfterLogin />

          <div className="DashMidBx">
            <SideBar />
            <Tokensidebar />
          </div>
        </div>
      </div>
    </>
  );
};

export default TransferTokens;
