import React, { useState, useEffect } from "react";
import HeaderAfterLogin from "../../common-components/header-afterlogin/header-afterlogin";
import SideBar from '../../common-components/sidebar/sidebar';
import HowSidebar from './how-it-works-sidebar';
import "./how-it-works.scss";


const TransferTokens = () => {

  return (
    <>
      <div className="mainBg">
        <div className="dash_container">
          <HeaderAfterLogin />

          <div className="DashMidBx">
            <SideBar />
            <HowSidebar />
          </div>
        </div>
      </div>
    </>
  );
};

export default TransferTokens;
