import { collectionAction } from '../actionType'
import config from '../../config/config'
import { multiPartData } from '../../utils'
import axios from 'axios'

export const createCollectionInitiate = () => ({
  type: collectionAction.CREATE_COLLECTION_INITIATE,
})

export const createCollectionSuccess = (data) => ({
  type: collectionAction.CREATE_COLLECTION_SUCCESS,
  payload: data,
})

export const createCollectionFailure = (data) => ({
  type: collectionAction.CREATE_COLLECTION_FAILURE,
})

export function createCollection(payload) {
  return (dispatch) => {
    const data = multiPartData(payload)
    dispatch(createCollectionInitiate())
    return new Promise((resolve, reject) =>
      axios
        .post(`${config.Url}createCollection`, data, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(createCollectionSuccess(data))
          } else {
            dispatch(createCollectionFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(createCollectionFailure(err))
          reject(err)
        }),
    )
  }
}


//----------------------------------- COLLECTION LIST ---------------------------------------//

export const collectionListInitiate = () => ({
  type: collectionAction.COLLECTION_LIST_INITIATE,
})

export const collectionListSuccess = (data) => ({
  type: collectionAction.COLLECTION_LIST_SUCCESS,
  payload: data,
})

export const collectionListFailure = () => ({
  type: collectionAction.COLLECTION_LIST_FAILURE,
})

export function collectionList(payload) {
  return (dispatch) => {
    dispatch(collectionListInitiate())
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}collectionList?category=${payload.category}&pageNumber=${payload.pageCount}`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(collectionListSuccess(data))
          } else {
            dispatch(collectionListFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(collectionListFailure(err))
          reject(err)
        }),
    )
  }
}

//----------------------------------- COLLECTION DETAIL ---------------------------------------//

export const collectionDetailInitiate = () => ({
  type: collectionAction.COLLECTION_DETAIL_INITIATE,
})

export const collectionDetailSuccess = (data) => ({
  type: collectionAction.COLLECTION_DETAIL_SUCCESS,
  payload: data,
})

export const collectionDetailFailure = () => ({
  type: collectionAction.COLLECTION_DETAIL_FAILURE,
})

export function collectionDetail(payload) {
  return (dispatch) => {
    dispatch(collectionDetailInitiate())
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}collectionDetail?collectionId=${payload}`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(collectionDetailSuccess(data))
          } else {
            dispatch(collectionDetailFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(collectionDetailFailure(err))
          reject(err)
        }),
    )
  }
}