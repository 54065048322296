import { transactionTokenAction } from "../actionType";
import config from '../../config/config'
import axios from 'axios'
//-----------------------------------TOKEN TRANSACTION CREATE ---------------------------------------//

export const transactionTokenAddInitiate = () => ({
    type: transactionTokenAction.TRANSACTION_TOKEN_ADD_INITIATE,
  })
  
  export const transactionTokenAddSuccess = (data) => ({
    type: transactionTokenAction.TRANSACTION_TOKEN_ADD_SUCCESS,
    payload: data,
  })
  
  export const transactionTokenAddFailure = () => ({
    type: transactionTokenAction.TRANSACTION_TOKEN_ADD_FAILURE,
  })
  
  export function transactionTokenCreate(payload) {
    return (dispatch) => {
      dispatch(transactionTokenAddInitiate())
      return new Promise((resolve, reject) =>
        axios
          .post(`${config.Url}transactionTokenCreate`,payload,{
            headers: {}
          })
          .then((response) => {
            const data = response.data
            if (data.code && data.code == 200) {
              dispatch(transactionTokenAddSuccess(data))
            } else {
              dispatch(transactionTokenAddFailure(data))
            }
            resolve(data)
          })
          .catch((err) => {
            dispatch(transactionTokenAddFailure(err))
            reject(err)
          }),
      )
    }
  }


  //-----------------------------------TOKEN TRANSACTION LIST ---------------------------------------//

export const transactionTokenListInitiate = () => ({
    type: transactionTokenAction.TRANSACTION_TOKEN_LIST_INITIATE,
  })
  
  export const transactionTokenListSuccess = (data) => ({
    type: transactionTokenAction.TRANSACTION_TOKEN_LIST_SUCCESS,
    payload: data,
  })
  
  export const transactionTokenListFailure = () => ({
    type: transactionTokenAction.TRANSACTION_TOKEN_LIST_FAILURE,
  })
  
  export function transactionTokenList(payload) {
    return (dispatch) => {
    let Fdate=payload.fromDate?payload.fromDate:''
    let Tdate=payload.toDate?payload.toDate:''
      dispatch(transactionTokenListInitiate())
      return new Promise((resolve, reject) =>
        axios
          .get(`${config.Url}transactionTokenList?pageNumber=${payload.pageNumber}&search=${payload.searchVal}&fromDate=${Fdate}&toDate=${Tdate}`,{
            headers: {}
          })
          .then((response) => {
            const data = response.data
            if (data.code && data.code == 200) {
              dispatch(transactionTokenListSuccess(data))
            } else {
              dispatch(transactionTokenListFailure(data))
            }
            resolve(data)
          })
          .catch((err) => {
            dispatch(transactionTokenListFailure(err))
            reject(err)
          }),
      )
    }
  }