import React, { useState, useEffect } from "react";
import { Accordion, AccordionItem } from '@szhsin/react-accordion';


const Rightsidebar = () => {

  return (
    <>
      <div className="dashRightside">
           <div className="howitOuter">
                <h2 className="howHd">How it works?</h2>
                <div className="accordialList">
                  <Accordion>
                    <AccordionItem header="What if I’m not sure which video I need?">
                    We have expert local production crews in over 70 cities worldwide.We have expert local production crews in over 70 cities worldwide.We have expert local production crews in over 70 cities worldwide.We have expert local production crews in over 70 cities worldwide.
                    </AccordionItem>
                    <AccordionItem header="What if I’m not sure which video I need?">
                    We have expert local production crews in over 70 cities worldwide.We have expert local production crews in over 70 cities worldwide.We have expert local production crews in over 70 cities worldwide.We have expert local production crews in over 70 cities worldwide.
                    </AccordionItem>
                    <AccordionItem header="What if I’m not sure which video I need?">
                    We have expert local production crews in over 70 cities worldwide.We have expert local production crews in over 70 cities worldwide.We have expert local production crews in over 70 cities worldwide.We have expert local production crews in over 70 cities worldwide.
                    </AccordionItem>
                    <AccordionItem header="What if I’m not sure which video I need?">
                    We have expert local production crews in over 70 cities worldwide.We have expert local production crews in over 70 cities worldwide.We have expert local production crews in over 70 cities worldwide.We have expert local production crews in over 70 cities worldwide.
                    </AccordionItem>
                    <AccordionItem header="What if I’m not sure which video I need?">
                    We have expert local production crews in over 70 cities worldwide.We have expert local production crews in over 70 cities worldwide.We have expert local production crews in over 70 cities worldwide.We have expert local production crews in over 70 cities worldwide.
                    </AccordionItem>
                    <AccordionItem header="What if I’m not sure which video I need?">
                    We have expert local production crews in over 70 cities worldwide.We have expert local production crews in over 70 cities worldwide.We have expert local production crews in over 70 cities worldwide.We have expert local production crews in over 70 cities worldwide.
                    </AccordionItem>
                  </Accordion>
                </div>
                <h2 className="howHd">KEY FEATURES</h2>
                <div className="featList">
                  <ul>
                    <li>
                      <h2>1</h2>
                      <p>Access to a variety of games under the Gamesphere ecosystem</p>
                    </li>
                    <li>
                      <h2>2</h2>
                      <p>Gateway to Gamesphere's native marketplace to buy, sell and trade gaming assets</p>
                    </li>
                    <li>
                      <h2>3</h2>
                      <p>Low gas fee and fast transactions</p>
                    </li>
                  </ul>
                </div>
           </div>
      </div>
    </>
  );
};

export default Rightsidebar;
