import { useWeb3Modal } from "@web3modal/react";
import { useState } from "react";
import { useAccount, useDisconnect, useBalance, useProvider, useContract } from "wagmi";
import { Button } from "reactstrap";
import { RiExchangeDollarLine, RiLogoutBoxLine } from "react-icons/ri"

//import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { adminConnectWallet } from "../../../redux/action/loginAction";

export default function CustomButton() {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { open } = useWeb3Modal();
    const { isConnected } = useAccount();
    const { disconnect } = useDisconnect();
    const label = isConnected ? "Logout " : "Connect Wallet";

    const account = useAccount({
        onConnect({ address, connector, isReconnected }) {
            let data = { walletAddress: address }
            dispatch(adminConnectWallet(data))
                .then((res) => {
                    if (res.code == 200) {
                        // toast.success(res.message, {
                        //   position: toast.POSITION.TOP_RIGHT,
                        // });
                    } else {
                        // toast.error(res.message, {
                        //   position: toast.POSITION.TOP_RIGHT,
                        // });
                    }
                })
                .catch((err) => {
                    console.log(`error>>>> ${err}`);
                });
        }
    });




    async function onOpen() {
        setLoading(true);
        await open();
        setLoading(false);
    }

    const onClick = () => {
        if (isConnected) {
            disconnect();
            localStorage.removeItem('userData');
            window.location.assign("./");
            //openModal()
        } else {
            onOpen();
        }
    }




    return (
        <>
            <Button onClick={onClick} disabled={loading} className="logoutBtn">
                {loading ? "Loading..." : label}
            </Button>

        </>
    );
}
