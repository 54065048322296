import React, { Component } from 'react';
import { Routes, Route,Navigate,Outlet} from "react-router-dom";
import Login from '../components/pages/login/login';
import Forget from '../components/pages/forgetPassword/forget';
import Otp from '../components/pages/verifyotp/otp'
import Rsetpassword from '../components/pages/resetPassword/resetPassword';
import Register from '../components/pages/signup/signup';
import Dashboard from '../components/pages/dashboard/dashboard';
import TransferTokens from '../components/pages/transfer-tokens/transfer-tokens';
import Partners from '../components/pages/partners/partners';
import HowitWorks from '../components/pages/how-it-works/how-it-works';
import Transaction from '../components/pages/transaction/transaction';
import User from '../components/pages/user/user';
import Nfts from '../components/pages/nfts/nfts';
import NftsDetails from '../components/pages/nfts-details/nfts-details';
import Collections from '../components/pages/collections/collections';
import CollectionsDetails from '../components/pages/collections-details/collections-details';
import CreateNfts from '../components/pages/create-nfts/create-nfts';
import CreateCollections from '../components/pages/create-collections/create-collections';

let tokens = localStorage.getItem('adminToken');
const ProtectedRoute = () => {
    let auth = tokens
    return auth ? <Outlet /> : <Navigate to="/" />
  }

export default class Layout extends Component {
   
    render() {
        return (
            <Routes>
                {/* public access url */}
                <Route path="/" element={<Login />} /> 
                <Route path="/forget-password" element={<Forget />} /> 
                <Route path="/verify-otp" element={<Otp />} /> 
                <Route path="/rset-password" element={<Rsetpassword />} />
                <Route path="/signup" element={<Register />} />
                <Route path="/dashboard" element={<Dashboard />} /> 
                <Route path="/transfer-tokens" element={<TransferTokens />} /> 
                <Route path="/partners" element={<Partners />} /> 
                <Route path="/how-it-works" element={<HowitWorks />} /> 
                <Route path="/transaction" element={<Transaction />} />    
                <Route path="/user" element={<User />} />  
                <Route path="/nfts" element={<Nfts />} />
                <Route path="/nfts-details" element={<NftsDetails />} />
                <Route path="/collections" element={<Collections />} />
                <Route path="/collections-details" element={<CollectionsDetails />} />
                <Route path="/create-nfts" element={<CreateNfts />} />
                <Route path="/create-collections" element={<CreateCollections />} />
            </Routes>
        )
        
    }
}