import { nftAction } from "../actionType";

const initialState = {
    loader: false,
    nftListData: [],
    nftDetailData: {},
    colNftListData: []
};
export default function NFT(state = initialState, { type, payload }) {
    switch (type) {
        case nftAction.NFT_LIST_INITIATE: {
            return {
                ...state,
                loader: true,
                nftListData: [],
            };
        }
        case nftAction.NFT_LIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                nftListData: payload,
            };
        }
        case nftAction.NFT_LIST_FAILURE: {
            return {
                ...state,
                loader: false,
                nftListData: [],
            };
        }

        case nftAction.NFT_DETAIL_INITIATE: {
            return {
                ...state,
                loader: true,
                nftDetailData: {},
            };
        }
        case nftAction.NFT_DETAIL_SUCCESS: {
            return {
                ...state,
                loader: false,
                nftDetailData: payload,
            };
        }
        case nftAction.NFT_DETAIL_FAILURE: {
            return {
                ...state,
                loader: false,
                nftDetailData: {},
            };
        }


        case nftAction.COLLECTION_NFT_LIST_INITIATE: {
            return {
                ...state,
                loader: true,
                colNftListData: [],
            };
        }
        case nftAction.COLLECTION_NFT_LIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                colNftListData: payload,
            };
        }
        case nftAction.COLLECTION_NFT_LIST_FAILURE: {
            return {
                ...state,
                loader: false,
                colNftListData: [],
            };
        }
        default:
            return state;
    }
}
