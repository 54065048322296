import React, { useState, useEffect } from "react";
import PartnerImg1 from '../../../assets/images/partner-logo1.png'
import PartnerImg2 from '../../../assets/images/partner-logo2.png'
import PartnerImg3 from '../../../assets/images/partner-logo3.png'
import PartnerImg4 from '../../../assets/images/partner-logo4.png'
import PartnerImg5 from '../../../assets/images/partner-logo5.png'
import PartnerImg6 from '../../../assets/images/partner-logo6.png'


const Rightsidebar = () => {

  return (
    <>
      <div className="dashRightside">
           <div className="partnersBx">
                <div className="partnersHd">
                  <h2>Our partners</h2>
                </div>
                <div className="partnersList">
                  <ul>
                    <li><img src={PartnerImg1} alt='' /></li>
                    <li><img src={PartnerImg2} alt='' /></li>
                    <li><img src={PartnerImg3} alt='' /></li>
                    <li><img src={PartnerImg4} alt='' /></li>
                    <li><img src={PartnerImg5} alt='' /></li>
                    <li><img src={PartnerImg6} alt='' /></li>
                  </ul>
                </div>
           </div>
      </div>
    </>
  );
};

export default Rightsidebar;
