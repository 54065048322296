import { userAction } from '../actionType'
import config from '../../config/config'
import axios from 'axios'
//----------------------------------- user LIST ---------------------------------------//

export const userListInitiate = () => ({
    type: userAction.USER_LIST_INITIATE,
  })
  
  export const userListSuccess = (data) => ({
    type: userAction.USER_LIST_SUCCESS,
    payload: data,
  })
  
  export const userListFailure = () => ({
    type: userAction.USER_LIST_FAILURE,
  })
  
  export function userList(payload) {
    return (dispatch) => {
      dispatch(userListInitiate())
      return new Promise((resolve, reject) =>
        axios
          .get(`${config.Url}userList?pageNumber=${payload.pageNumber}&search=${payload.searchVal}`,{
            headers: {}
          })
          .then((response) => {
            const data = response.data
            if (data.code && data.code == 200) {
              dispatch(userListSuccess(data))
            } else {
              dispatch(userListFailure(data))
            }
            resolve(data)
          })
          .catch((err) => {
            dispatch(userListFailure(err))
            reject(err)
          }),
      )
    }
  }