import React, { useState, useEffect } from "react";
import { Button, Input, FormGroup, Label } from "reactstrap";
import Header from '../../common-components/header/header'
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'js-cookie';
import "./resetPassword.scss";
import { resetPassword } from "../../../redux/action/loginAction";

const initialState = {
    cPassword: "",
    password: "",
    rememberme: false,
    
    cPasswordIdError: "",
    passwordError: "",
    errorMsg: "",
};

const Rsetpassword = () => {
    const [iState, updateState] = useState(initialState);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { cPassword, password, cPasswordIdError, passwordError, errorMsg } = iState;
    let email = localStorage.getItem('email');
    let adminId = localStorage.getItem('adminId');
    const handleChange = (e) => {
        const { name, value } = e.target;
        updateState({
            ...iState,
            [name]: value,
        });
    };

    let handleValidation = () => {
        let cPasswordIdError = "";
        let passwordError = "";
        let formIsValid = true;

        if (!cPassword) {
            cPasswordIdError = "Please Enter Confirm Password.";
            formIsValid = false;
        }
        if (password != cPassword) {
            cPasswordIdError = "Password Does not Match.";
            formIsValid = false;
        }
        if (!password) {
            passwordError = "Please Enter New Password.";
            formIsValid = false;
        }


        updateState({
            ...iState,
            cPasswordIdError,
            passwordError,
            errorMsg,
        });
        return formIsValid;
    };



    const handleSubmit = async (e) => {
        e.preventDefault()
        let formIsValid = handleValidation()
        if (formIsValid) {
            const data = { newPassword: password, email, adminId }
            dispatch(resetPassword(data))
                .then((res) => {
                    if (res.code == 200) {

                        toast.success(res.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        })
                        setTimeout(() => {
                            navigate('/')
                        }, 2000)
                    } else {
                        toast.error(res.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        })
                        updateState({
                            ...iState,
                            errorMsg: res.message,
                            cPasswordIdError: '',
                            passwordError: '',
                        })
                    }
                })
                .catch((err) => {
                    console.log(`error>>>> ${err}`)
                })
        }
    }
    return (
        <>
            <div className="loginOuterBg">
                <div className="container">
                    <Header />
                    <ToastContainer />
                    <div className="LoginOuter">
                        <div className="loginLeft">
                            <h2>enter the<br />game sphere</h2>
                        </div>
                        <div className="LoginBg">
                            <div className="loginBgInn">
                                <div className="loginHd">Reset Password</div>
                                <form>

                                    <div className="frmbx">
                                        <FormGroup>
                                            <label>New Password</label>
                                            <Input
                                                type="password"
                                                name="password"
                                                value={password}
                                                onChange={handleChange}
                                                id="password"
                                                placeholder="New Password"
                                            />
                                        </FormGroup>
                                    </div>
                                    <span style={{ color: 'red' }}>{passwordError}</span>

                                    <div className="frmbx">
                                        <FormGroup>
                                            <label>Confirm Password</label>
                                            <Input
                                                type="password"
                                                name="cPassword"
                                                value={cPassword}
                                                onChange={handleChange}
                                                id="Password"
                                                placeholder="Confirm Password"
                                            />
                                        </FormGroup>
                                    </div>
                                    <span style={{ color: 'red' }}>{cPasswordIdError}</span>
                                    <div className="loginbtn">
                                        <Button onClick={handleSubmit}>Rset Password</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Rsetpassword;
