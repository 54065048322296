import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { useProvider } from "wagmi";
import ImageIcon from "../../../assets/images/img-icon.png"
import { BiArrowBack } from "react-icons/bi";
import Switch from "../../common-components/switch/switch"
import { useNavigate } from 'react-router-dom';
import { createCollection } from "../../../redux/action/collectionAction";
const initialState = {
    bannerImage: "",
    bannerImageReview: "",
    collectionImage: "",
    collectionImageReview: "",
    collectionName: "",
    externallink: "",
    description: "",
    category: "",
    blockchainType: "",
    content: "",
    chainId: "",
    errors: "",
};

const Rightsidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const provider = useProvider();
    const [chain, setchain] = useState('');
    const [iState, updateState] = useState(initialState);
    const {
        bannerImage,
        bannerImageReview,
        collectionImage,
        collectionImageReview,
        collectionName,
        externallink,
        description,
        category,
        blockchainType,
        content,
        chainId,
        errors } = iState;


    const getChain = async () => {
        let aw = await provider.getNetwork()
        setchain(aw)
    }
    useEffect(() => {
        getChain()
    })


    const handleChange = (e) => {
        const { name, value } = e.target;
        updateState({
            ...iState,
            [name]: value,
        });
    };


    const collectionFileHandler = (e) => {
        const file = e.target.files[0]

        if (file) {
            if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
                collectionPreviewsFile(file)

            } else {
                toast.error('Only formats are allowed: jpeg, jpg, png files', {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        }
    }


    const collectionPreviewsFile = (file) => {
        const reader = new FileReader()
        reader.addEventListener("load", () => {
            updateState({
                ...iState, collectionImageReview: reader.result, collectionImage: file
            })
        }, false)
        if (file) {
            reader.readAsDataURL(file)
        }
    }

    const bannerFileHandler = (e) => {
        const file = e.target.files[0]

        if (file) {
            if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
                bannerPreviewsFile(file)

            } else {
                toast.error('Only formats are allowed: jpeg, jpg, png files', {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        }
    }


    const bannerPreviewsFile = (file) => {
        const reader = new FileReader()
        reader.addEventListener("load", () => {
            updateState({
                ...iState, bannerImageReview: reader.result, bannerImage: file
            })
        }, false)
        if (file) {
            reader.readAsDataURL(file)
        }
    }

    let handleValidation = () => {
        let bannerImageEmpty = ''
        let collectionImageEmpty = ''
        let collectionNameEmpty = ''
        let externallinkEmpty = ''
        let descriptionEmpty = ''
        let categoryEmpty = ''
        let blockchainTypeEmpty = ''
        let contentEmpty = ''
        let formIsValid = true;

        if (collectionImageReview === '') {
            collectionImageEmpty = "Please select collection picture";
            formIsValid = false;
        }

        if (bannerImageReview === '') {
            bannerImageEmpty = "Please select banner picture.";
            formIsValid = false;
        }
        if (!collectionName.trim()) {
            collectionNameEmpty = "Please insert collection name.";
            formIsValid = false;
        }
        if (!externallink.trim()) {
            externallinkEmpty = "Please insert URL";
            formIsValid = false;
        }
        if (!description.trim()) {
            descriptionEmpty = "Please insert discription"
            formIsValid = false;
        }
        if (!category.trim()) {
            categoryEmpty = "Please select cotegory";
            formIsValid = false;
        }

        if (!blockchainType.trim()) {
            blockchainTypeEmpty = "Please select blockchain Type.";
            formIsValid = false;
        }
        // if (!content.trim()) {
        //     contentEmpty = "Please insert content";
        //     formIsValid = false;
        // }



        updateState({
            ...iState, errors: {
                bannerImageEmpty,
                collectionImageEmpty,
                collectionNameEmpty,
                externallinkEmpty,
                descriptionEmpty,
                categoryEmpty,
                blockchainTypeEmpty,
                contentEmpty,

            }

        })
        return formIsValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        let formIsValid = handleValidation()
        if (formIsValid) {
            const data = {
                bannerImage,
                collectionImage,
                collectionName,
                externallink,
                description,
                category,
                blockchainType,
                content: "vascscS",
                chainId: chain.chainId,
            }
            dispatch(createCollection(data))
                .then((res) => {
                    if (res.code == 200) {

                        toast.success(res.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        })
                        setTimeout(() => {
                            navigate('/')
                        }, 2000)
                    } else {
                        toast.error(res.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        })
                        updateState({
                            ...iState,
                            errorMsg: res.message,
                            cPasswordIdError: '',
                            passwordError: '',
                        })
                    }
                })
                .catch((err) => {
                    console.log(`error>>>> ${err}`)
                })
        }
    }


    return (
        <>
            <div className="dashRightside">
                <div className="nftsOuterBx">
                    <div className="nftDetHd"><BiArrowBack /> Create Collection</div>
                    <div className="uploadNftImage upldBanner">
                        <Label>Collection Image*</Label>
                        <span>This image will appear at the top of your collection page</span>
                        <i>{collectionImageReview ? <span>
                            <img src={collectionImageReview} alt="" /> <br />

                        </span> : <span>
                            <img src={ImageIcon} alt="" /> <br />
                            Recommended size: 1400 x 350
                        </span>
                        }

                            <Input type="file" className="createnftFile" onChange={collectionFileHandler}
                                onClick={e => e.target.value == null}
                                accept=".png, .jpg, .jpeg" />
                        </i>
                        <span style={{ color: "red" }}>{errors.collectionImageEmpty}</span>
                    </div>

                    <div className="uploadNftImage upldBanner">
                        <Label>Banner Image*</Label>
                        <span>This image will appear at the top of your collection page</span>
                        <i>
                            {
                                bannerImageReview ? <span>
                                    <img src={bannerImageReview} alt="" /> <br />
                                </span> : <span>
                                    <img src={ImageIcon} alt="" /> <br />
                                    Recommended size: 1400 x 350
                                </span>
                            }

                            <Input type="file" className="createnftFile" onChange={bannerFileHandler}
                                onClick={e => e.target.value == null}
                                accept=".png, .jpg, .jpeg" />
                        </i>
                        <span style={{ color: "red" }}>{errors.bannerImageEmpty}</span>
                    </div>

                    <div className="createfrmOuter">
                        <div className="createFrmBx">
                            <Label>Name*</Label>
                            <Input type="text" 
                                name="collectionName"
                                value={collectionName}
                                onChange={handleChange} className="createInp" />
                            <span style={{ color: "red" }}>{errors.collectionNameEmpty}</span>
                        </div>

                        <div className="createFrmBx">
                            <Label>External link</Label>
                            <Input type="text" name="externallink"
                                value={externallink}
                                onChange={handleChange} className="createInp" placeholder="hrrps://gamesphere.com/item" />
                            <span style={{ color: "red" }}>{errors.externallinkEmpty}</span>
                        </div>
                        <div className="createFrmBx">
                            <Label>Description</Label>
                            <Input type="textarea" name="description"
                                value={description}
                                onChange={handleChange} className="createInp2" />
                            <span style={{ color: "red" }}>{errors.descriptionEmpty}</span>
                        </div>
                        <div className="createFrmBx">
                            <Label>Category</Label>
                            <Input type="select" name="category" value={category} onChange={handleChange} className="selInp">
                                <option>Select collection</option>
                                <option value="Cricket">Cricket</option>
                                <option value="Hockey">Hockey</option>
                                <option value="Football">Football</option>
                                <option value="Volleyball">Volleyball</option>

                            </Input>
                            <span style={{ color: "red" }}>{errors.categoryEmpty}</span>
                        </div>
                        <div className="createFrmBx">
                            <Label>Blockchain</Label>
                            <Input type="select" name="blockchainType" value={blockchainType} onChange={handleChange} className="selInp">
                                <option value="GSP">GSP</option>
                                <option value="Polygon">Polygon</option>

                            </Input>
                            <span style={{ color: "red" }}>{errors.blockchainTypeEmpty}</span>
                        </div>
                        <div className="unloackable">
                            <div className="unloackTop">
                                <div className="unlockLeft">
                                    <strong>Explicit & Sensitive Content</strong>
                                    <span>Set this item as explicit and sensitive content</span>
                                </div>
                                <div className="unlockRight">
                                    <Switch />
                                </div>
                            </div>
                            <span style={{ color: "red" }}>{errors.contentEmpty}</span>
                        </div>

                        <div className="createBtn">
                            <Button onClick={handleSubmit}>Create</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Rightsidebar;
