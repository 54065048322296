
import { usdPriceAction } from "../actionType";

const initialState = {
  loader: false,
  usdPriceData:{},
  usdPriceList:[],
};
export default function gspUsdPrice(state = initialState, { type, payload }) {
  switch (type) {
    case usdPriceAction.USD_PRICE_INITIATE: {
      return {
        ...state,
        loader: true,
        usdPriceData:{},
      };
    }
    case usdPriceAction.USD_PRICE_SUCCESS: {
      return {
        ...state,
        loader: false,
        usdPriceData: payload,
      };
    }
    case usdPriceAction.USD_PRICE_FAILURE: {
      return {
        ...state,
        loader: false,
        usdPriceData:{},
      };
    }

    case usdPriceAction.USD_PRICE_LIST_INITIATE: {
      return {
        ...state,
        loader: true,
        usdPriceList:[],
      };
    }
    case usdPriceAction.USD_PRICE_LIST_SUCCESS: {
      return {
        ...state,
        loader: false,
        usdPriceList: payload,
      };
    }
    case usdPriceAction.USD_PRICE_LIST_FAILURE: {
      return {
        ...state,
        loader: false,
        usdPriceList:[],
      };
    }
    default:
      return state;
  }
}
