import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from 'reactstrap';
import TimeCountdown from "../../pages/nfts-details/time-countdown"
import Token from "../../../assets/images/svg-icon.png"
import PriceChart from "../../../assets/images/price-chart.png"

import {BiArrowBack} from "react-icons/bi";
import {BsGraphUp, BsTagsFill, BsListUl} from "react-icons/bs";
import { NftDetail } from "../../../redux/action/nftAction";


const NftsDetailsRightsidebar = () => {
    const dispatch = useDispatch();
    const listData = useSelector((state) => state.NFT);
    const { nftDetailData } = listData;
    let nftId = localStorage.getItem('nftId')
    useEffect(() => {
        dispatch(NftDetail(nftId));
    }, [NftDetail]);

    return (
        <>
            <div className="dashRightside">
                <div className="nftsOuterBx">
                    <div className="nftDetOuterBx">
                        <div className="nftDetHd"><BiArrowBack /> NFT Details</div>
                        <div className="nftDetailBx">
                            <h2>The Orbitians</h2>
                            <small>Minted on Sep 30, 2022</small>
                            <strong>Created By</strong>
                            <span><img src={Token} alt="" /> Game Sphere</span>
                            <strong>Description</strong>
                            <p>The Orbitians<br/>
                                is a collection of 10,000 unique NFTs on the Ethereum blockchain,</p>
                            <p>There are all sorts of beings in the NFT Universe. The most advanced and<br/> friendly of the bunch are Orbitians. </p>
                        </div>
                        <div className="bidOuter">
                            <div className="bidInn">
                                <span>From</span>
                                <strong>21.58 GSP</strong>
                                <span>$1,063</span>
                            </div>
                            <div className="bidInn">
                                <span>From</span>
                                <strong>21.58 GSP</strong>
                                <span>$1,063</span>
                            </div>
                        </div>
                        <div className="timerBg">
                            <strong>Auction Ends In</strong>
                            <TimeCountdown />
                        </div>
                        <div className="priceHistOuter">
                            <div className="pricehistHd"><BsGraphUp /> Price History</div>
                            <div className="priceChart">
                                <img src={PriceChart} alt="" />
                            </div>
                        </div>
                        <div className="priceHistOuter">
                            <div className="pricehistHd"><BsTagsFill /> Listings</div>
                            <div className="priceTable">
                                <Table>
                                    <thead>
                                    <tr>
                                        <th>Price</th>
                                        <th>USD Price</th>
                                        <th>Expiration</th>
                                        <th>From</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>15.45 GSP</td>
                                            <td>$18,798.91</td>
                                            <td>about 1 month</td>
                                            <td>8onFES</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <div className="priceHistOuter">
                            <div className="pricehistHd"><BsListUl /> Offers</div>
                            <div className="priceTable">
                                <Table>
                                    <thead>
                                    <tr>
                                        <th>Price</th>
                                        <th>USD Price</th>
                                        <th>Floor Differences</th>
                                        <th>Expiration</th>
                                        <th>From</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>15.45 GSP</td>
                                            <td>$18,798.91</td>
                                            <td>100%</td>
                                            <td>about 1 month</td>
                                            <td>8onFES</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NftsDetailsRightsidebar;
