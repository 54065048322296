import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import ImageIcon from "../../../assets/images/img-icon.png"
import { BiArrowBack } from "react-icons/bi";
import { useProvider } from "wagmi";
import { useNavigate } from 'react-router-dom';
import Switch from "../../common-components/switch/switch"
import { collectionList } from "../../../redux/action/collectionAction";
import { createNft } from "../../../redux/action/nftAction";

const initialState = {
    nftImage: "",
    nftImageReview: "",
    nftName: "",
    externallink: "",
    description: "",
    collectionId: "",
    properties: "",
    supply: "",
    blockchainType: "",
    chainId: "",
    errors: "",
};
const Rightsidebar = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const provider = useProvider();
    const [chain, setchain] = useState('');
    const [iState, updateState] = useState(initialState);
    const listData = useSelector((state) => state.collection);
    const { loader, collectionListData } = listData;
    const {
        nftImage,
        nftImageReview,
        nftName,
        externallink,
        description,
        collectionId,
        properties,
        supply,
        blockchainType,
        chainId,
        errors } = iState;


    const getChain = async () => {
        let aw = await provider.getNetwork()
        setchain(aw)
    }
    useEffect(() => {
        getChain()
    })
    useEffect(() => {
        dispatch(collectionList());
    }, [collectionList]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        updateState({
            ...iState,
            [name]: value,
        });
    };


    const fileHandler = (e) => {
        const file = e.target.files[0]

        if (file) {
            if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
                previewsFile(file)

            } else {
                toast.error('Only formats are allowed: jpeg, jpg, png files', {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        }
    }


    const previewsFile = (file) => {
        const reader = new FileReader()
        reader.addEventListener("load", () => {
            updateState({
                ...iState, nftImageReview: reader.result, nftImage: file
            })
        }, false)
        if (file) {
            reader.readAsDataURL(file)
        }
    }

    let handleValidation = () => {
        let nftImageEmpty = ''
        let nftNameEmpty = ''
        let externallinkEmpty = ''
        let descriptionEmpty = ''
        let collectionIdEmpty = ''
        let blockchainTypeEmpty = ''
        let supplyEmpty = ''
        let formIsValid = true;

        if (nftImage === '') {
            nftImageEmpty = "Please select NFT picture";
            formIsValid = false;
        }
        if (!nftName.trim()) {
            nftNameEmpty = "Please insert NFT name.";
            formIsValid = false;
        }
        if (!externallink.trim()) {
            externallinkEmpty = "Please insert URL";
            formIsValid = false;
        }
        if (!description.trim()) {
            descriptionEmpty = "Please insert discription"
            formIsValid = false;
        }
        if (!collectionId.trim()) {
            collectionIdEmpty = "Please select collection";
            formIsValid = false;
        }

        if (!blockchainType.trim()) {
            blockchainTypeEmpty = "Please select blockchain Type.";
            formIsValid = false;
        }
        if (!supply.trim()) {
            supplyEmpty = "Please insert supply";
            formIsValid = false;
        }



        updateState({
            ...iState, errors: {
                nftImageEmpty,
                nftNameEmpty,
                externallinkEmpty,
                descriptionEmpty,
                collectionIdEmpty,
                blockchainTypeEmpty,
                supplyEmpty,

            }

        })
        return formIsValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        let formIsValid = handleValidation()
        if (formIsValid) {
            const data = {
                nftImage,
                nftName,
                externallink,
                description,
                collectionId,
                properties: 'test',
                supply,
                blockchainType,
                chainId: '80001'
            //chainId: chain.chainId,
            }
            dispatch(createNft(data))
                .then((res) => {
                    console.log('res-------->',res)
                    if (res.code == 200) {

                        toast.success(res.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        })
                        setTimeout(() => {
                            navigate('/nfts')
                        }, 2000)
                    } else {
                        toast.error(res.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        })
                        updateState({
                            ...iState,
                            errorMsg: res.message
                        })
                    }
                })
                .catch((err) => {
                    console.log(`error>>>> ${err}`)
                })
       }
    }

    return (
        <>
            <div className="dashRightside">
                <div className="nftsOuterBx">
                    <div className="nftDetHd"><BiArrowBack /> Create NFT</div>
                    <div className="uploadNftImage">
                        <Label>Image, Video, Audio, or 3D Model*</Label>
                        <span>File type supported: JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV, OGG, GLB, GLTE, Max size: 100MB</span>
                        <i>

                            {
                                nftImageReview ?
                                    <img src={nftImageReview} alt="" />
                                    :
                                    <img src={ImageIcon} alt="" />
                            }

                            <Input type="file" className="createnftFile" onChange={fileHandler}
                                onClick={e => e.target.value == null}
                                accept=".png, .jpg, .jpeg" />
                        </i>
                        <span style={{ color: "red" }}>{errors.nftImageEmpty}</span>
                    </div>
                    <div className="createfrmOuter">
                        <div className="createFrmBx">
                            <Label>Name*</Label>
                            <Input type="text"
                                name="nftName"
                                value={nftName}
                                onChange={handleChange}
                                className="createInp" />
                            <span style={{ color: "red" }}>{errors.nftNameEmpty}</span>
                        </div>
                        <div className="createFrmBx">
                            <Label>External link</Label>
                            <Input type="text" name="externallink"
                                value={externallink}
                                onChange={handleChange} className="createInp" placeholder="hrrps://gamesphere.com/item" />
                            <span style={{ color: "red" }}>{errors.externallinkEmpty}</span>
                        </div>
                        <div className="createFrmBx">
                            <Label>Description</Label>
                            <Input type="textarea" name="description"
                                value={description}
                                onChange={handleChange} className="createInp2" />
                            <span style={{ color: "red" }}>{errors.descriptionEmpty}</span>
                        </div>
                        <div className="createFrmBx">
                            <Label>Collection</Label>
                            <span>This is the collection where your item will appear.</span>
                            <Input type="select" name="collectionId"
                                value={collectionId}
                                onChange={handleChange} className="selInp" >
                                <option>Select collection</option>
                                {collectionListData && collectionListData.data &&
                                    collectionListData.data.length > 0 ? (
                                    collectionListData.data.map((item, i) => (

                                        <option value={item._id}>{item.collectionName}</option>
                                    ))
                                ) : ""
                                }

                            </Input>
                            <span style={{ color: "red" }}>{errors.collectionIdEmpty}</span>
                        </div>
                        <div className="propertyList">
                            <div className="propertyLeft">
                                <strong>Properties</strong>
                                <span>Textual traits that show up as rectangles</span>
                            </div>
                            <div className="propertyAdd">
                                <Button>+</Button>
                            </div>
                        </div>
                        <div className="propertyList">
                            <div className="propertyLeft">
                                <strong>Levels</strong>
                                <span>Numerical traits that show as a progress bar</span>
                            </div>
                            <div className="propertyAdd">
                                <Button>+</Button>
                            </div>
                        </div>
                        <div className="propertyList">
                            <div className="propertyLeft">
                                <strong>Stats</strong>
                                <span>Numerical traits that just show as numbers</span>
                            </div>
                            <div className="propertyAdd">
                                <Button>+</Button>
                            </div>
                        </div>
                        {/* <div className="unloackable">
                            <div className="unloackTop">
                                <div className="unlockLeft">
                                    <strong>Unlockable Content</strong>
                                    <span>Include unlockable content that can only be revealed by the owner of the item.</span>
                                </div>
                                <div className="unlockRight">
                                    <Switch />
                                </div>
                            </div>
                            <div className="unlockTxtarea">
                                <Input type="textarea" name="name" className="createInp2" />
                            </div>
                        </div>
                        <div className="unloackable">
                            <div className="unloackTop">
                                <div className="unlockLeft">
                                    <strong>Unlockable Content</strong>
                                    <span>Include unlockable content that can only be revealed by the owner of the item.</span>
                                </div>
                                <div className="unlockRight">
                                    <Switch />
                                </div>
                            </div>
                        </div>*/}
                        <div className="createFrmBx">
                            <Label>Supply</Label>
                            <span>The number of items that can be minted. No gas cost to you!</span>
                            <Input type="text"  name="supply"
                                value={supply}
                                onChange={handleChange} className="createInp" placeholder="" />
                                 <span style={{ color: "red" }}>{errors.supplyEmpty}</span>
                        </div> 
                        <div className="createFrmBx">
                            <Label>Blockchain</Label>
                            <Input type="select"
                                name="blockchainType"
                                value={blockchainType}
                                onChange={handleChange} className="selInp">
                                <option value="GSP">GSP</option>
                                <option value="Polygon">Polygon</option>

                            </Input>
                            <span style={{ color: "red" }}>{errors.blockchainTypeEmpty}</span>
                        </div>
                        <div className="createBtn">
                            <Button onClick={handleSubmit}>Create</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Rightsidebar;
