import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { FiDownload } from "react-icons/fi"
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import {useProvider} from "wagmi";
import { userList } from "../../../redux/action/userAction";
import { TbRefresh } from "react-icons/tb"
import { CSVLink } from "react-csv";
const Usersidebar = () => {
    const dispatch = useDispatch();
    const [search, setSearch] = useState('')
    const listData = useSelector((state) => state.user);
    const [perPage, SetcurrentPage] = useState(10)
    const { userListData } = listData;
    const filename = 'gamers.csv';
    const [chain, setchain] = useState('');
    const provider = useProvider();
    let data = userListData.data && userListData.data[0].data.length > 0 ? userListData.data && userListData.data[0].data : []
    let countUserData =
        userListData && (userListData.data && userListData.data[0].total.length > 0) ? userListData.data[0].total[0].count / 10 : []
   
        const getChain = async () => {
            let aw = await provider.getNetwork()
            setchain(aw)
        }
        useEffect(() => {
            getChain()
        })
   
        useEffect(() => {
        let obj = { searchVal: '', pageNumber: 1 }
        dispatch(userList(obj));
    }, [userList]);


    // const transactionIdChange = (trId) => {
    //     let ar = trId.split('')
    //     ar.splice(4, 50, ".....")
    //     return ar.join('')
    // }

    // const fromAddressChange = (fromAddress) => {
    //     let ar = fromAddress.split('')
    //     ar.splice(4, 34, ".....")
    //     return ar.join('')
    // }
    const handlePageChange = (e) => {
        const selectedPage = e.selected + 1
        SetcurrentPage(10 * selectedPage)
        let obj = { searchVal: '', pageNumber: selectedPage }
        dispatch(userList(obj))
    }

    const walletAddressChange = (address) => {
        if (address) {
            let ar = address.split('')
            ar.splice(4, 34, ".....")
            return ar.join('')
        }

    }

    const handleSearch = (e) => {
        const { name, value } = e.target;
        setSearch(value)
        let obj = { searchVal: value, pageNumber: 1 }
        dispatch(userList(obj));
    }

    const refreshPage = () => {
        let obj = { searchVal: '', pageNumber: 1 }
        dispatch(userList(obj));
        setSearch("")
    }

    return (
        <>
            <div className="dashRightside">
                <div className="transBg">
                    <div className="transUserOuter">
                        <div className="transHd">Gamers</div>
                        <div className="transFltr">
                            <div className="daterange">
                            </div>
                            <div className="downBtn">
                                <CSVLink data={data} filename={filename}>
                                    <Button><FiDownload /> </Button>
                                </CSVLink>
                            </div>
                            <div className="srchBx">
                                <FormGroup>
                                    <Input type="text" name="search" id="exampleEmail" placeholder="Search..." value={search} onChange={handleSearch} />
                                </FormGroup>
                            </div>
                            <div className="refreshBtn">
                                <Button onClick={refreshPage}><TbRefresh /></Button>
                            </div>

                        </div>
                    </div>

                    <div className="tableBx">
                        <table cellPadding="0" cellSpacing="0" border="0">
                            <thead>
                                <tr><th>Sr. No.</th>
                                    <th>User id</th>
                                    <th>Email</th>
                                    <th> Balance</th>
                                    <th> Balance</th>
                                    <th>Login Time</th>
                                </tr>
                            </thead>
                            <tbody>

                                {userListData.data && userListData.data[0].data.length > 0 ? (
                                    userListData.data[0].data.map((data, i) => (
                                        <tr key={i}>
                                            <td>{i + 1 + (perPage - 10)}</td>
                                            <td>{walletAddressChange(data.address)}</td>
                                            <td>{data.email}</td>
                                            <td>{data.ethBalance && data.ethBalance.toFixed(4)} {chain.chainId === 80001 ? "Matic" : "BNB"}</td>
                                            <td>{data.gspBalance && data.gspBalance.toFixed(4)} GSP</td>
                                            <td>{moment(data.updatedAt).format("DD/MM/YYYY HH:mm A")}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr><td>Data not found</td></tr>
                                )
                                }

                            </tbody>
                        </table>
                    </div>
                    <div className="paginationBx">
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={Math.ceil(countUserData)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChange}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Usersidebar;
