import { collectionAction } from "../actionType";

const initialState = {
    loader: false,
    collectionListData: [],
    collectionDetailData:{}
};
export default function collection(state = initialState, { type, payload }) {
    switch (type) {
        case collectionAction.COLLECTION_LIST_INITIATE: {
            return {
                ...state,
                loader: true,
                collectionListData: [],
            };
        }
        case collectionAction.COLLECTION_LIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                collectionListData: payload,
            };
        }
        case collectionAction.COLLECTION_LIST_FAILURE: {
            return {
                ...state,
                loader: false,
                collectionListData: [],
            };
        }

        case collectionAction.COLLECTION_DETAIL_INITIATE: {
            return {
                ...state,
                loader: true,
                collectionDetailData:{},
            };
        }
        case collectionAction.COLLECTION_DETAIL_SUCCESS: {
            return {
                ...state,
                loader: false,
                collectionDetailData: payload,
            };
        }
        case collectionAction.COLLECTION_DETAIL_FAILURE: {
            return {
                ...state,
                loader: false,
                collectionDetailData:{},
            };
        }
        default:
            return state;
    }
}
