import React from "react";
import { Button } from "reactstrap";
import Logo from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";
import "./header-afterlogin.scss";
import CustomButton from "../../pages/customButton/customButton";
import { useAccount } from "wagmi";
import {Web3NetworkSwitch } from "@web3modal/react";
import { RxCaretDown } from "react-icons/rx";

const HeaderAfterLogin = () => {
  const { address } = useAccount();

  const walletAddressChange = (address) => {
    let ar = address.split('')
    ar.splice(4, 34, ".....")
    return ar.join('')
  }
  return (
    <>
      <header className="aftloginheader">
        <div className="headLeft">
          <Link to="/dashboard">
            <img src={Logo} alt="" />
          </Link>
        </div>
        <div className="headRight">
        <Web3NetworkSwitch />
          {address ? <Button className="loginUser">
           {walletAddressChange(address)}
            <i></i>
            {/* <RxCaretDown /> */}
          </Button> :<CustomButton />}
          {/* <CustomButton /> */}

        </div>
      </header>
    </>
  );
};

export default HeaderAfterLogin;
