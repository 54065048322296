import React, { useState, useEffect } from "react";
import TokenIcon from '../../../assets/images/Token_icon.png'
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { Link } from 'react-router-dom'
import { BsChevronDown } from "react-icons/bs"
import { useDispatch, useSelector } from "react-redux";
import { useAccount, useSigner, useProvider, useContract } from "wagmi";
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import { FaCalendarAlt } from "react-icons/fa"
import erc from '../../../smartContractFunction/abi.json'
import ico from '../../../smartContractFunction/ico.json'
import { transactionTokenCreate, transactionTokenList } from '../../../redux/action/transactionTokenAction'
import { ethers } from "ethers";
import { FiDownload } from "react-icons/fi"
import ReactLoading from 'react-loading';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { TbRefresh } from "react-icons/tb"
import { CSVLink } from "react-csv";
const initialState = {
  tokenAddress: "",
  amount: "",
  search: "",
  fromDate: "",
  toDate: "",
  isFlag: false,
  errors: {},
};

const Rightsidebar = () => {
  const dispatch = useDispatch();
  const [iState, updateState] = useState(initialState);
  const [gspbalance, setGspbalance] = useState("");

  const [getbnbBalance, setGetbnbBalance] = useState("");
  const [feesBalance, setFeesBalance] = useState("");
  const [chain, setchain] = useState('');
  const [perPage, SetcurrentPage] = useState(10)
  const tokenListData = useSelector((state) => state.tokenTransaction);
  const { tokenTransactionListData } = tokenListData;
  const { tokenAddress, amount, search, fromDate, toDate, isFlag, errors } = iState;
  const filename = 'tokens.csv';
  const headers = ['bnbValue', 'gspValue', 'status',];
  let data = tokenTransactionListData.data && tokenTransactionListData.data[0].data.length > 0 ? tokenTransactionListData.data && tokenTransactionListData.data[0].data : []
  const provider = useProvider();
  const { data: signer } = useSigner();
  const { address } = useAccount();


  const getChain = async () => {
    let aw = await provider.getNetwork()
    setchain(aw)
  }
  useEffect(() => {
    getChain()
  })
  let adrserc;
  if (chain.chainId == 80001) {
    adrserc = process.env.REACT_APP_ERC_POLYGON_ADDRESS
  } else {
    adrserc = process.env.REACT_APP_ERC_BSC_ADDRESS
  }

  const contract = useContract({
    address: adrserc,
    abi: erc,
    signerOrProvider: signer,
  });

  const getGspBalance = async () => {
    const bnbBalance = await provider.getBalance(signer && signer._address);
    let bnbBal = parseInt(bnbBalance._hex, 16) / 1000000000000000000;
    setGetbnbBalance(bnbBal);
    const approve = await contract.balanceOf(signer && signer._address);
    let gspBal = parseInt(approve._hex, 16) / 1000000000000000000;
    setGspbalance(gspBal);
  };
  let countData = tokenTransactionListData && (tokenTransactionListData.data && tokenTransactionListData.data[0].total.length > 0) ? tokenTransactionListData.data[0].total[0].count / 10 : []

  useEffect(() => {
    getGspBalance();
  }, [signer]);

  const handleInputChange = (e) => {
    let gspbalanceError = "";
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value
    });
    if (name == 'amount') {
      if (value > gspbalance) {
        gspbalanceError = "Insufficient balance";
        updateState({
          errors: { gspbalanceError }
        });
      } else if (value < 0) {
        gspbalanceError = "Insufficient balance";
        updateState({
          errors: { gspbalanceError }
        });
      }
      else {
        updateState({
          ...iState,
          [name]: value,
          errors: { gspbalanceError: "" },
        });
      }
    }

    if (tokenAddress && amount) {
      getFees()
    }
  }
  useEffect(() => {
    if (tokenAddress && amount) {
      getFees()
    }

  });
  const getFees = async () => {
    const estimation = await contract.estimateGas.transfer(tokenAddress, (amount * 1000000000000000000).toString());
    let feesBal = estimation.toString()
    feesBal = ethers.utils.formatEther(feesBal)
    setFeesBalance(feesBal)
  }

  let handleValidation = () => {
    let tokenAddressError = "";
    let amountError = "";
    let formIsValid = true;

    if (!tokenAddress) {
      tokenAddressError = "Please enter address.";
      formIsValid = false;
    }
    if (!amount) {
      amountError = "Please enter amount.";
      formIsValid = false
    }
    updateState({
      ...iState, errors: { tokenAddressError, amountError, }
    });
    return formIsValid;
  };

  const transferToken = async () => {
    let formIsValid = handleValidation()
    if (formIsValid) {
      const transferTokenBal = await contract.transfer(tokenAddress, (amount * 1000000000000000000).toString());
      if (transferTokenBal) {
        updateState({
          ...iState,
          tokenAddress: '',
          amount: ''
        });
      }
      if (transferTokenBal) {
        let hash = transferTokenBal.hash;
        let from = transferTokenBal.from;
        let to = tokenAddress;
        let status = "success";
        let gspValue = amount;
        let data = { hash, from, to, gspValue, status };
        dispatch(transactionTokenCreate(data))
          .then((res) => {
            if (res.code == 200) {
              updateState({
                ...iState,
                isFlag: true,
                tokenAddress: "",
                amount: "",
                errors: {}
              });
            } else {
              console.log("error");
              updateState({
                ...iState,
                tokenAddress: "",
                amount: "",
                errors: {}
              });
            }
          })
          .catch((err) => {
            console.log(`error>>>> ${err}`);
          });

        setTimeout(() => {

          toast.success("Token transfer successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            isFlag: false,
            tokenAddress: "",
            amount: "",
            errors: {}
          });
          let obj = { searchVal: '', pageNumber: 1 }
          getGspBalance();
          dispatch(transactionTokenList(obj))

        }, 15000);

      } else {
        console.log("error");
      }
    }
  };

  useEffect(() => {
    let obj = { searchVal: '', pageNumber: 1 }
    dispatch(transactionTokenList(obj));
  }, [transactionTokenList]);


  const transactionIdChange = (trId) => {
    let ar = trId.split('')
    ar.splice(4, 50, ".....")
    return ar.join('')
  }

  const fromAddressChange = (fromAddress) => {
    let ar = fromAddress.split('')
    ar.splice(4, 34, ".....")
    return ar.join('')
  }


  const handlePageChange = (e) => {
    const selectedPage = e.selected + 1
    SetcurrentPage(10 * selectedPage)
    let obj = { searchVal: '', pageNumber: selectedPage }
    dispatch(transactionTokenList(obj))
  }

  const maxValue = () => {
    updateState({
      ...iState,
      amount: gspbalance
    });

  }

  const handleSearch = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value
    });
    let obj = { searchVal: value, pageNumber: 1 }
    dispatch(transactionTokenList(obj));
  }

  const handleEvent = (event, picker) => {
    console.log(picker.startDate);
  }

  const handleCallback = (start, end) => {
    let fromDate = moment(start._d).format("YYYY-MM-DD")
    let toDate = moment(end._d).format("YYYY-MM-DD")
    let obj = { searchVal: '', pageNumber: 1, fromDate, toDate }
    dispatch(transactionTokenList(obj));
  }
  const refreshPage = () => {
    let obj = { searchVal: '', pageNumber: 1 }
    dispatch(transactionTokenList(obj));
    updateState({ ...iState, search: "" })
  }


  // const filterRecord = (e) => {
  //   let obj = { searchVal: '', pageNumber: 1, fromDate, toDate }
  //   dispatch(transactionTokenList(obj));
  // }

  return (
    <>
      <div className="dashRightside">
        <ToastContainer />
        <div className="dashRightBx">
          <div className="tokensOuter">
            <div className="gspFldBx">
              <div className="selGsp">
                <img src={TokenIcon} alt="" />
                GSP
              </div>
              <FormGroup>
                <Input type="select" name="select" id="exampleSelect">
                  <option>{gspbalance}</option>
                </Input>
              </FormGroup>
              <BsChevronDown />
            </div>
            <div className="gspadd">
              <FormGroup>
                <Input type="text" name="tokenAddress" id="examplePassword" placeholder="To Address" value={tokenAddress}
                  onChange={handleInputChange} />
              </FormGroup>
              <span style={{ color: "red" }}>{errors.tokenAddressError}</span>
            </div>
            <div className="withdrawOuter">
              <div className="withdraw">
                <FormGroup>
                  <Input type="number" name="amount" id="examplePassword" placeholder="GSP Amount" value={amount}
                    onChange={handleInputChange} />
                </FormGroup>
                <span style={{ color: 'red' }}>{errors.amountError}</span>
              </div>
              <div className="maxBx" onClick={maxValue}>
                <Link><span >MAX</span></Link>
              </div>
            </div>
            <div className="freetext">
              <span>Gas Fee: {feesBalance} {chain.chainId === 80001 ? "Matic" : "BNB"}</span>
              {errors && (
                <span style={{ color: "red" }}>{errors.gspbalanceError}</span>
              )}
            </div>
            <Button className="transfBtn" onClick={transferToken} disabled={isFlag}>Transfer Tokens</Button>
            {
              isFlag ?
                <div className="loaderOuter" >
                  <ReactLoading type="bars" className="loaderInn" />
                </div> : ""
            }
          </div>


        </div>
        <div className="transBg">
          <div className="transUserOuter">
            <div className="transHd">Token Transfer Transactions</div>
            <div className="transFltr">
              <div className="downBtn">
                <CSVLink data={data} filename={filename}>
                  <Button><FiDownload /> </Button>
                </CSVLink>
              </div>
              <div className="dateInpBx">
                <DateRangePicker
                  onEvent={handleEvent} onCallback={handleCallback}
                >
                  <input type="text" className="dateInp" />
                </DateRangePicker>
                <FaCalendarAlt />
              </div>
              <div className="srchBx">
                <FormGroup>
                  <Input type="text" name="search" id="exampleEmail" placeholder="Search..." value={search} onChange={handleSearch} />
                </FormGroup>
              </div>
              <div className="refreshBtn">
                <Button onClick={refreshPage}><TbRefresh /></Button>
              </div>

              {/* <div className="filterBtn">
                <Button onClick={filterRecord}><FaFilter /></Button>
              </div> */}
            </div>
          </div>
          <div className="tableBx">
            <table cellPadding="0" cellSpacing="0" border="0">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Tx. ID</th>
                  <th>Date</th>
                  <th>Value</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>

                {
                  tokenTransactionListData.data &&
                    tokenTransactionListData.data[0].data.length > 0 ? (
                    tokenTransactionListData.data[0].data.map((data, i) => (
                      <tr key={i}>
                        <td>{i + 1 + (perPage - 10)}</td>
                        <td><a href={`https://testnet.bscscan.com/tx/${data.hash}`} target="_blank">{transactionIdChange(data.hash)}</a></td>
                        <td>{moment(data.createdAt).format("DD/MM/YYYY")}</td>

                        <td>{data.gspValue} GSP</td>
                        <td>{fromAddressChange(data.from)}</td>
                        <td>{fromAddressChange(data.to)}</td>
                        <td>
                          <span className="approved">{data.status}</span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr><td>Data not found</td></tr>
                  )
                }

              </tbody>
            </table>
          </div>
          <div className="paginationBx">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={Math.ceil(countData)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={'pagination'}
              activeClassName={'active'}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Rightsidebar;
